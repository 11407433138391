var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.filteredForms.length && !_vm.searchQuery.length)?_c('div',{staticClass:"text-center pa-3 mb-2"},[_c('h1',{staticClass:"text-h1 font-weight-600 text-black text-center mb-2 pt-5"},[_vm._v("Create your first form!")]),_c('p',{staticClass:"text-black font-size-root text-center font-weight-thin mb-2"},[_vm._v("Create your first form and assign it.")]),_c('v-img',{staticClass:"my-10",attrs:{"src":_vm.coverIllustration,"max-height":"300","contain":""}}),_c('v-btn',{staticClass:"font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm",attrs:{"elevation":"0","height":"43","color":"#6F70FF"},on:{"click":function($event){return _vm.createNewForm()}}},[_vm._v(" Create First Form ")])],1):(!_vm.filteredForms.length && _vm.searchQuery.length)?_c('div',{staticClass:"text-center pa-3 mb-2"},[_c('h1',{staticClass:"text-h1 font-weight-600 text-black text-center mb-2 pt-5"},[_vm._v("Not Found")]),_c('p',{staticClass:"text-black font-size-root text-center font-weight-thin mb-2"},[_vm._v("No results found for: "+_vm._s(_vm.searchQuery))]),_c('v-img',{staticClass:"my-10",attrs:{"src":_vm.notFoundIllustration,"max-height":"300","contain":""}})],1):(_vm.filteredForms.length)?_c('v-row',[_vm._l((_vm.filteredForms),function(form,index){return _c('v-col',{key:index,attrs:{"sm":"6","md":"6","lg":"4","xl":"3"}},[_c('v-card',{staticClass:"card-shadow-2 pa-1 ma-2",attrs:{"solo":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"ma-2 mb-0 py-2",attrs:{"color":"#F3F4FD"}},[(form.status === 'Published')?_c('v-img',{attrs:{"src":_vm.formIllustration,"max-height":"200","max-width":"326","contain":""}}):_vm._e(),(form.status === 'Draft')?_c('v-img',{attrs:{"src":_vm.formIllustration2,"max-height":"200","max-width":"326","contain":""}}):_vm._e(),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","opacity":"0.1"}},[(form.status != 'Published')?_c('router-link',{staticClass:"text-decoration-none btn-hover no-default-hover",attrs:{"to":("/edit-form/" + (form.id))}},[_c('v-btn',{staticClass:"font-weight-600 text-capitalize btn-primary rounded-xs mr-4",attrs:{"elevation":"0","height":"40","outlined":"","color":"#6F70FF"}},[_c('span',{staticClass:"text-primary"},[_vm._v("View Form")])])],1):_c('router-link',{staticClass:"text-decoration-none btn-hover no-default-hover",attrs:{"to":("/preview-form/" + (form.id))}},[_c('v-btn',{staticClass:"font-weight-600 text-capitalize btn-primary rounded-xs mr-4",attrs:{"elevation":"0","height":"40","outlined":"","color":"#6F70FF"}},[_c('span',{staticClass:"text-primary"},[_vm._v("View Preview")])])],1)],1):_vm._e()],1)],1)]}}],null,true)}),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',[_c('v-card-title',{staticClass:"font-weight-600 subtitle-2 d-block pl-2"},[_vm._v(_vm._s(form.name))]),_c('v-card-subtitle',{staticClass:"font-weight-400 subtitle-2 d-block pl-2"},[_vm._v(_vm._s(form.status)+" ")])],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-5",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('feather',{staticClass:"text-darker",attrs:{"type":"more-vertical","size":"1.2rem","stroke-width":"1.2"}})],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.duplicateForm(form)}}},[_c('v-list-item-title',[_vm._v("Duplicate Form")])],1),(form.status !== 'Published')?_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-title',[_vm._v("Delete Form")])],1)]}}],null,true),model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h2 font-weight-600"},[_vm._v("Delete")]),_c('v-card-text',[_vm._v(" Are you sure you want to delete "+_vm._s(form.name)+" form"),_c('span',{staticClass:"font-weight-600"}),_vm._v("? ")]),_c('v-card-actions',{staticClass:"pl-6"},[_c('v-btn',{staticClass:"font-weight-600 text-capitalize btn-ls btn-outline-primary py-3 px-6 rounded-sm me-2 mb-2",attrs:{"elevation":"0","height":"43","color":"transparent"},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v("No")]),_c('v-btn',{staticClass:"font-weight-600 text-capitalize btn-primary py-3 px-6 mb-2 rounded-sm",attrs:{"elevation":"0","height":"43","color":"#6F70FF"},on:{"click":function($event){return _vm.deleteForm(form)}}},[_vm._v("Yes")])],1)],1)],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.hideForm(form)}}},[_c('v-list-item-title',[_vm._v("Hide Form")])],1)],1)],1)],1),_c('v-row',{staticClass:"px-4"},[_c('v-col',{staticClass:"pb-0 pl-1 d-flex justify-space-between",attrs:{"cols":"12"}},[_c('div',{staticClass:"font-weight-400 label text-light d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('feather',_vm._g(_vm._b({staticClass:"text-darker mr-1",attrs:{"type":"calendar","size":"1.2rem","stroke-width":"1.2"}},'feather',attrs,false),on)),(form.schedule)?_c('span',{staticClass:"text-light"},[_vm._v(_vm._s(_vm.nextAvailableTime(form.schedule)))]):_c('span',{staticClass:"text-light"},[_vm._v("No Schedule")])]}}],null,true)},[_c('span',[_vm._v("Schedule")])])],1),(form.schedule && form.schedule.type !== 'None')?_c('div',{staticClass:"font-weight-400 label text-light d-flex align-center"},[_c('span',{staticClass:"text-light"},[_vm._v(_vm._s(form.schedule.type))])]):_vm._e()]),((_vm.authUser.role === 'GlobalManager' || _vm.authUser.role === 'Admin') && form.manager_id !== _vm.authUser.id)?_c('v-col',{staticClass:"ma-0 py-2 pb-1 pl-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"font-weight-400 label text-light d-flex align-center"},[_c('feather',{staticClass:"text-darker mr-1",attrs:{"type":"user","size":"1.2rem","stroke-width":"1.2"}}),_c('span',[_vm._v(" Created by "),_c('span',{staticClass:"font-weight-600"},[_vm._v(_vm._s(_vm.retrieveUserWhoCreatedForm(form.manager_id)))])])],1)]):_vm._e(),((_vm.authUser.role === 'GlobalManager' || _vm.authUser.role === 'Admin') && form.manager_id === _vm.authUser.id)?_c('v-col',{staticClass:"ma-0 py-2 pb-1 pl-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"font-weight-400 label text-light d-flex align-center"},[_c('feather',{staticClass:"text-darker mr-1",attrs:{"type":"user","size":"1.2rem","stroke-width":"1.2"}}),_c('span',[_vm._v(" Created by you ")])],1)]):_vm._e(),_c('v-col',{staticClass:"ma-0 py-2 pb-1 pl-1",attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{staticClass:"pt-0 d-flex justify-space-between pl-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"font-weight-400 label text-light d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('feather',_vm._g(_vm._b({staticClass:"text-darker mr-1",attrs:{"type":"edit","size":"1.2rem","stroke-width":"1.2"}},'feather',attrs,false),on)),_vm._v(" "+_vm._s(_vm.lastEdited(form.updatedAt))+" ")]}}],null,true)},[_c('span',[_vm._v("Last Updated")])])],1),_c('assign-user-menu',{attrs:{"form":form,"disabled":true}})],1)],1)],1)],1)}),(_vm.totalPagesLength > 1)?_c('v-col',{staticClass:"mt-4 d-flex justify-end",attrs:{"cols":"12"}},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa fa-angle-left","next-icon":"fa fa-angle-right","color":"#6F70FF","length":_vm.totalPagesLength,"circle":""},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }