
<script>
import { Pie } from 'vue-chartjs/legacy';

export default {
    extends: Pie,
    props: {
        labels: {
            type: Array,
            default: () => {}
        },
        chartData: {
            type: Array,
            default: () => {}
        },
        chartId: {
            type: String
        }
    },
    data() {
        return {
            data: {
                // labels: ["Danger", "Warning", "Success", "Primary", "Info"],
                labels: this.labels,
                datasets: [
                    {
                        label: 'Dataset 1',
                        tension: 0.4,
                        data: this.chartData,
                        backgroundColor: ['#6F70FF', '#D8D9FE', '#F3F3F3', '#000000', '#EAEAEA'],
                        showLine: false,
                        barPercentage: 1.6
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltips: {
                        enabled: true,
                        mode: 'index',
                        intersect: false
                    }
                },
                scales: {
                    y: {
                        type: 'linear',
                        grid: {
                            drawBorder: false,
                            display: false
                        },
                        ticks: {
                            display: false
                        }
                    },
                    x: {
                        type: 'linear',
                        grid: {
                            drawBorder: false,
                            display: false
                        },
                        ticks: {
                            display: false
                        }
                    }
                },
                layout: {
                    padding: 0
                }
            }
        };
    },
    mounted() {
        this.renderChart(this.data, this.options, this.chartId);
    }
};
</script>