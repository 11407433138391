<template>
    <v-menu v-if="form" transition="slide-y-transition" :disabled="disabled" offset-y :position-x="50" right>
        <template #activator="menu">
            <div class="assignee-menu" v-if="!form.assignees || !form.assignees.length">
                <v-tooltip bottom>
                    <template #activator="tooltip">
                        <v-btn class="ml-4" icon v-on="{ ...menu.on, ...tooltip.on }">
                            <v-avatar size="33" color="#F3F4FD">
                                <span v-if="!form.assignees" class="text-primary text-h5 font-weight-600">All</span>
                                <feather v-else type="user-plus" class="text-darker" size="20" stroke-width="1.5"></feather>
                            </v-avatar>
                        </v-btn>
                    </template>
                    <span v-if="!disabled">Assign to</span>
                    <span v-else-if="!form.assignees">All</span>
                    <span v-else>Unassigned</span>
                </v-tooltip>
            </div>
            <div v-else class="avatars-group stacked assignee-menu" :class="form.assignees.length > 1 ? 'ml-0' : 'ml-2'">
                <v-tooltip v-for="(assigneeId, i) in form.assignees.slice(0, 3)" :key="i" bottom>
                    <template #activator="tooltip">
                        <v-btn class="avatars-group-item" icon v-on="{ ...menu.on, ...tooltip.on }">
                            <v-avatar size="33" color="#F3F4FD">
                                <span v-if="!retrieveUser(assigneeId).avatar" class="text-primary text-h5 font-weight-600">{{ userInitials(retrieveUser(assigneeId)) }}</span>
                                <img v-else :src="`${$feathersConnectionString}/${retrieveUser(assigneeId).avatar.url}`" alt="User" />
                            </v-avatar>
                        </v-btn>
                    </template>
                    <span>{{ retrieveUser(assigneeId).first_name }} {{ retrieveUser(assigneeId).last_name }}</span>
                </v-tooltip>
                <v-btn v-if="form.assignees.length > 3" class="avatars-group-item" icon>
                    <v-avatar size="33" color="#F3F4FD">
                        <span class="text-primary text-h5 font-weight-600">+{{ form.assignees.length - form.assignees.slice(0, 3).length }}</span>
                    </v-avatar>
                </v-btn>
            </div>
        </template>
        <v-list min-width="215" multiple max-width="215" class="assign" nav three-line>
            <div class="pa-2 my-4">
                <v-row>
                    <v-col cols="8" class="d-flex align-center py-0">
                        <v-card-title class="pa-0 mb-0 font-weight-600 subtitle-2">Assign to:</v-card-title>
                    </v-col>
                </v-row>
            </div>
            <v-list>
                <v-list-item-group :value="allUsersActive">
                    <v-list-item @click.stop="toggleAllUsers()" class="min-height-auto">
                        <v-list-item-avatar color="#F3F4FD" class="my-0 me-5 d-flex justify-center" width="37" min-width="15" height="37">
                            <span class="text-primary text-h5 font-weight-600">All</span>
                        </v-list-item-avatar>
                        <v-list-item-content class="pa-0">
                            <v-list-item-title class="text-darker">All Users</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
            <v-list-item-group multiple v-on:change="setAssignedUser" v-model="assignees">
                <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="ps-customizer-area">
                    <v-list-item @click.stop v-for="(user, i) in users" :key="i" class="min-height-auto">
                        <v-list-item-avatar color="#F3F4FD" class="my-0 me-5" width="37" min-width="15" height="37">
                            <img v-if="user.avatar" :src="`${$feathersConnectionString}/${user.avatar.url}`" alt="User" />
                            <span v-else class="text-primary text-h5 ml-3 font-weight-600">{{ userInitials(user) }}</span>
                        </v-list-item-avatar>

                        <v-list-item-content class="pa-0">
                            <v-list-item-title class="text-darker">{{ user.first_name }} {{ user.last_name }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </vue-perfect-scrollbar>
            </v-list-item-group>
        </v-list>
    </v-menu>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { makeFindMixin } from 'feathers-vuex';

export default {
    components: {
        VuePerfectScrollbar
    },
    props: {
        form: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            assigneesList: [],
            perfectScrollbarSettings: {
                maxScrollbarLength: 50,
                wheelPropagation: false
            }
        };
    },
    mixins: [makeFindMixin({ service: 'users', watch: true })],
    methods: {
        setAssignedUser(assigneesArr) {
            this.form.assignees = [];

            if (assigneesArr && assigneesArr.length) {
                assigneesArr.forEach((assigneeIndex, arrIndex) => {
                    this.form.assignees[arrIndex] = this.users[assigneeIndex].id;
                });
            }
        },
        toggleAllUsers() {
            if (this.form.assignees) {
                this.form.assignees = null;
                this.assignees = [];
            } else {
                this.form.assignees = [];
                this.assignees = [];
            }
        },
        userInitials(user) {
            const initials = (user.first_name + ' ' + user.last_name).match(/\b\w/g) || [];

            return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        },
        retrieveUser(assigneeId) {
            const foundUser = this.users.find((user) => user.id === assigneeId);

            if (foundUser === undefined) {
                return false;
            }

            return foundUser;
        }
    },
    computed: {
        usersParams() {
            return {
                query: {
                    $sort: true,
                    role: {
                        $ne: 'Admin'
                    }
                }
            };
        },
        assignees: {
            get() {
                if (!this.assigneesList) {
                    this.assigneesList = []
                }

                if (this.assigneesList && this.form.assignees && !this.assigneesList.length) {
                    this.form.assignees.forEach((assigneeId) => {
                        const userFound = this.users.findIndex((user) => {
                            return user.id === assigneeId;
                        });

                        if (userFound >= 0) {
                            this.assigneesList.push(userFound);
                        }
                    });
                }

                return this.assigneesList;
            },
            set(assignees) {
                this.assigneesList = assignees;
            }
        },
        allUsersActive() {
            if (this.form.assignees) {
                return -1
            } else {
                return 0
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.ps-customizer-area {
    max-height: 10rem;
}

.assignee-menu {
    display: inline-block;
}

.avatars-group {
    &.stacked {
        display: inline-flex;
        flex-direction: row;
        direction: ltr;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;

        & > * {
            margin-right: -0.5rem;
        }

        & > *:first-of-type {
            margin-left: 1rem;
        }

        & > *:last-of-type {
            padding-right: 1rem;
        }
    }

    .avatars-group-item {
        transition: all 0.1s ease-out;

        &:hover {
            transform: translateY(-0.1rem);
            z-index: 1;
        }
    }
}

.assign {
    overflow: hidden !important;
}
</style> 
