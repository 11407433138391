<template>
    <div v-if="!filteredSubmissions.length && !searchQuery.length" class="text-center pa-3 mb-2">
        <h1 class="text-h1 font-weight-600 text-black text-center mb-2 pt-5">
            No forms completed!
        </h1>
        <p class="text-black font-size-root text-center font-weight-thin mb-2">
            You currently have no forms that have been completed.
        </p>
        <v-img class="my-10" :src="coverIllustration" max-height="300" contain />
    </div>
    <div v-else-if="!filteredSubmissions.length && searchQuery.length" class="text-center pa-3 mb-2">
        <h1 class="text-h1 font-weight-600 text-black text-center mb-2 pt-5">
            Not Found
        </h1>
        <p class="text-black font-size-root text-center font-weight-thin mb-2">
            No results found for: {{ searchQuery }}
        </p>
        <v-img class="my-10" :src="notFoundIllustration" max-height="300" contain />
    </div>
    <v-row v-else-if="filteredSubmissions.length">
        <v-col v-for="(submission, index) in filteredSubmissions" :key="index" cols="12" sm="12" md="6" lg="4" xl="3">
            <v-card class="card-shadow-2 pa-1 ma-2" solo>
                <v-hover>
                    <template v-slot:default="{ hover }">
                        <v-card class="ma-2 mb-0 py-2" color="#F3F4FD">
                            <v-img class="sm-max-width md-max-width" :src="formIllustration" max-height="200" contain />
                            <v-fade-transition>
                                <v-overlay v-if="hover" absolute opacity="0.1">
                                    <router-link :to="`/edit-submission/${submission.id}`" class="text-decoration-none btn-hover no-default-hover">
                                        <v-btn elevation="0" height="40" outlined class="font-weight-600 text-capitalize btn-primary rounded-xs mr-4" color="#6F70FF">
                                            <span class="text-primary">View Results</span>
                                        </v-btn>
                                    </router-link>
                                </v-overlay>
                            </v-fade-transition>
                        </v-card>
                    </template>
                </v-hover>
                <v-row class="px-4">
                    <v-col cols="12" class="pb-0 pl-0">
                        <v-card-title class="font-weight-600 subtitle-2 d-block pl-2">{{ submission.name }}</v-card-title>
                        <v-card-subtitle class="font-weight-400 subtitle-2 d-block pl-2">{{ submission.status }} </v-card-subtitle>
                    </v-col>
                    <v-col cols="12" class="ma-0 py-2 pb-1 pl-1">
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" class="pt-0 d-flex justify-space-between pl-1">
                        <div class="font-weight-400 label text-light d-flex align-center">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <feather type="check-circle" class="text-darker mr-1" size="1.2rem" v-bind="attrs" v-on="on" stroke-width="1.2"></feather>
                                    {{ lastEdited(submission.updatedAt) }}
                                </template>
                                <span>Completed</span>
                            </v-tooltip>
                        </div>
                        <v-avatar size="33" color="#F3F4FD">
                            <span class="text-primary text-h5 font-weight-600">Me</span>
                        </v-avatar>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col v-if="totalPagesLength > 1" cols="12" class="mt-4 d-flex justify-end">
            <v-pagination prev-icon="fa fa-angle-left" next-icon="fa fa-angle-right" class="pagination" color="#6F70FF" v-model="currentPage" :length="totalPagesLength" circle></v-pagination>
        </v-col>
        <!-- <pre>{{ submissions }}</pre> -->
    </v-row>
</template>

<script>
import { mapActions } from 'vuex';
import { makeFindMixin } from 'feathers-vuex';
import { mapGetters } from 'vuex';

export default {
    components: {},
    props: {
        searchQuery: {
            type: String,
            required: true
        },
        filterOption: {
            type: String,
            required: true
        },
        dateRange: {
            type: Array,
            required: true
        }
    },
    mixins: [makeFindMixin({ service: 'submissions', watch: true })],
    data() {
        return {
            formIllustration: require("@/assets/img/illustrations/undraw_Server_push_re_303w.svg"),
            coverIllustration: require("@/assets/img/illustrations/undraw_completed_tasks_vs6q.svg"),
            notFoundIllustration: require("@/assets/img/illustrations/undraw_no_data_re_kwbl.svg"),
            deleteDialog: false,
            currentPage: 1,
            perPage: 16,
            totalPages: 1
        };
    },
    methods: {
        lastEdited(time) {
            var d = new Date(time);
            return this.$moment(d).calendar();
        },
        ...mapActions('app', ['showSuccess', 'showError'])
    },
    computed: {
        filteredSubmissions() {
            return this.submissions.filter((submission) => {
                const submissionObj = JSON.stringify(submission);
                return submissionObj.includes(this.searchQuery);
            })
        },
        submissionsParams() {
            if (!this.authUser) return;
            
            const query = {
                assigned: {
                    $in: [this.authUser.id]
                },
                status: 'Submitted',
                $limit: this.perPage,
                $skip: (this.currentPage - 1) * this.perPage,
                $sort: {
                    updatedAt: -1
                }
            };

            let lessThanDate = ''
            let greaterThanDate = ''

            if (this.dateRange.length === 1) {
                lessThanDate = this.$moment(this.dateRange[0]).add(1, 'days')
                greaterThanDate = this.$moment(this.dateRange[0])

                query.createdAt = {
                    $gte: greaterThanDate,
                    $lte: lessThanDate
                }
            } else if (this.dateRange.length === 2) {
                lessThanDate = this.$moment(this.dateRange[1]).add(1, 'days')
                greaterThanDate = this.$moment(this.dateRange[0])

                query.createdAt = {
                    $gte: greaterThanDate,
                    $lte: lessThanDate
                }
            }

            if (this.filterOption === 'ascending') {
                query.$sort = {
                    name: 1
                };
            } else if (this.filterOption === 'descending') {
                query.$sort = {
                    name: -1
                };
            } else if (this.filterOption === 'latestCreated') {
                query.$sort = {
                    createdAt: -1
                };
            } else if (this.filterOption === 'oldestCreated') {
                query.$sort = {
                    createdAt: 1
                };
            } else if (this.filterOption === 'latestUpdated') {
                query.$sort = {
                    updatedAt: 1
                };
            } else if (this.filterOption === 'oldestUpdated') {
                query.$sort = {
                    updatedAt: 1
                };
            }

            return {
                query: query
            };
        },
        totalPagesLength() {
            const submissionsPaginationData = this.submissionsPaginationData.default;
            if (submissionsPaginationData && submissionsPaginationData.mostRecent.total) {
                this.totalPages = Math.ceil(submissionsPaginationData.mostRecent.total / this.perPage);

                if (submissionsPaginationData.mostRecent.total / this.totalPages === this.perPage) {
                    this.currentPage = 1;
                }
            }

            return this.totalPages;
        },
        ...mapGetters('apiAuth', { authUser: 'user' })
    }
};
</script>
<style lang="scss" scoped>
.card-shadow-2 {
    box-shadow: 0 0 1rem 0 rgb(136 152 170 / 15%) !important;
}

.label {
    font-size: 0.8rem;
}

@media screen and (max-width: 600px) {
    .sm-max-width {
        max-width: 150px;
    }
}

@media screen and (min-width: 601px) {
    .md-max-width {
        max-width: 326px;
    }
}
</style>



