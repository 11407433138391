<template>
    <div class="qr-container">
        <p class="text-black font-size-root text-center font-weight-thin mb-2">Scan a qr code</p>
        <p class="error">{{ error }}</p>
        <qrcode-stream :camera="camera" @decode="onDecode" :track="selected.value" @init="onInit"></qrcode-stream>
        <v-dialog v-model="qrDialog" width="500">
            <v-card>
                <v-card-title class="text-h2 font-weight-600 pa-4 mb-0 mb-0 text-center">Qr Code Results</v-card-title>
                <v-divider></v-divider>

                <v-card-text class="pa-4">
                    <p class="text-grey font-size-root font-weight-thin my-4">{{ result }}</p>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn @click="qrDialog = false; result = null" elevation="0" height="43" class="font-weight-600 btn-block text-capitalize btn-ls btn-outline-primary rounded-sm me-2 my-2" color="transparent">Close</v-btn>
                    <v-btn v-if="isValidHttpUrl(result)" @click="visitUrl(result)" elevation="0" height="43" class="font-weight-600 btn-block text-capitalize btn-primary rounded-sm" color="#6F70FF">Visit Form</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
    components: {
        QrcodeStream
    },
    data() {
        return {
            selected: { text: "outline", value: this.paintOutline },
            error: '',
            qrDialog: false,
            result: null,
            camera: 'auto'
        }
    },
    methods: {
        async onDecode (content) {
            this.result = content
            this.qrDialog = true

            this.pause()
            await this.timeout(500)
            this.unpause()
        },
        unpause () {
            this.camera = 'auto'
        },
        pause () {
            this.camera = 'off'
        },
        timeout (ms) {
            return new Promise(resolve => {
                window.setTimeout(resolve, ms)
            })
        },
        isValidHttpUrl(string) {
            let url;
            
            try {
                url = new URL(string);
            } catch (_) {
                return false;  
            }

            return (url.protocol === "http:" || url.protocol === "https:");
        },
        visitUrl(result) {
            window.open(result,"_self");
        },
        paintOutline (detectedCodes, ctx) {
            for (const detectedCode of detectedCodes) {
                const [ firstPoint, ...otherPoints ] = detectedCode.cornerPoints

                ctx.strokeStyle = "red";

                ctx.beginPath();
                ctx.moveTo(firstPoint.x, firstPoint.y);
                for (const { x, y } of otherPoints) {
                ctx.lineTo(x, y);
                }
                ctx.lineTo(firstPoint.x, firstPoint.y);
                ctx.closePath();
                ctx.stroke();
            }
        },
        async onInit (promise) {
            try {
                await promise
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    this.error = "ERROR: you need to grant camera access permission"
                } else if (error.name === 'NotFoundError') {
                    this.error = "ERROR: no camera on this device"
                } else if (error.name === 'NotSupportedError') {
                    this.error = "ERROR: secure context required (HTTPS, localhost)"
                } else if (error.name === 'NotReadableError') {
                    this.error = "ERROR: is the camera already in use?"
                } else if (error.name === 'OverconstrainedError') {
                    this.error = "ERROR: installed cameras are not suitable"
                } else if (error.name === 'StreamApiNotSupportedError') {
                    this.error = "ERROR: Stream API is not supported in this browser"
                } else if (error.name === 'InsecureContextError') {
                    this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
                } else {
                    this.error = `ERROR: Camera error (${error.name})`;
                }
            }
        }
    }
};
</script>
<style lang="scss" scoped>
</style>
