<template>
    <div ref="form" class="form-editor-page-wrapper">
        <v-card class="card-shadow pa-4">
            <div class="d-flex justify-space-between">
                <div class="d-flex flex-row align-center flex-grow-1">
                    <v-btn to="/form-manager" icon elevation="0" class="rounded-xs mr-2" color="#6F70FF">
                        <feather type="chevron-left" class="text-darker" stroke-width="1.5"></feather>
                    </v-btn>
                    <v-text-field
                        class="text-h2 font-weight-600"
                        v-model="formCopy.name"
                        :autofocus="!formCopy.name"
                        :readonly="formCopy.status === 'Published'"
                        solo
                        flat
                        placeholder="Form Name"
                        hide-details="auto"
                        dense
                        auto-grow></v-text-field>
                </div>
                <div class="d-flex align-center">
                    <schedule-menu :form="formCopy"></schedule-menu>
                    <assign-user-menu :form="formCopy"></assign-user-menu>
                    <v-dialog v-model="saveDialog" width="500">
                        <v-card>
                            <v-card-title class="text-h2 font-weight-600 pa-4 mb-0 mb-0 text-center">Save Form</v-card-title>
                            <v-divider></v-divider>

                            <v-card-text class="pa-4">
                                <p v-if="formValid" class="text-grey font-size-root font-weight-thin my-4">Are you sure you want to leave your changes unsaved?</p>
                                <p v-else class="text-grey font-size-root font-weight-thin my-4">Some of the required fields have not been filled in.</p>
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-btn
                                    elevation="0"
                                    height="43"
                                    class="font-weight-600 btn-block text-capitalize btn-ls btn-outline-primary rounded-sm me-2 my-2"
                                    color="transparent"
                                    @click.native="cancelSave"
                                    >Discard Changes</v-btn
                                >
                                <v-btn
                                    elevation="0"
                                    :disabled="!formValid"
                                    height="43"
                                    class="font-weight-600 btn-block text-capitalize btn-primary rounded-sm"
                                    color="#6F70FF"
                                    @click.native="confirmSave"
                                    >Save and Exit</v-btn
                                >
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="hideForm()" class="ml-4" icon v-bind="attrs" v-on="on">
                                <v-avatar v-if="formCopy.hide" size="33" color="#B7B8FF">
                                    <feather type="eye-off" class="text-darker" size="20" stroke-width="1.5"></feather>
                                </v-avatar>
                                <v-avatar v-else size="33" color="#F3F4FD">
                                    <feather type="eye" class="text-darker" size="20" stroke-width="1.5"></feather>
                                </v-avatar>
                            </v-btn>
                        </template>
                        <span v-if="formCopy.hide">Show Form</span>
                        <span v-else>Hide Form</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="pdfGeneration('empty-form')" class="ml-4" icon v-bind="attrs" v-on="on" :disabled="isGeneratingPDF" :loading="isGeneratingPDF">
                                <v-avatar size="33" color="#F3F4FD">
                                    <feather type="download" class="text-darker" size="20" stroke-width="1.5"></feather>
                                </v-avatar>
                            </v-btn>
                        </template>
                        <span>Download Form</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="saveForm()" :disabled="formNameDisabled" class="ml-4" icon v-bind="attrs" v-on="on">
                                <v-avatar size="33" :color="formNameDisabled ? '#E9E7E7' : '#F3F4FD'">
                                    <feather type="save" class="text-darker" size="20" stroke-width="1.5"></feather>
                                </v-avatar>
                            </v-btn>
                        </template>
                        <span>Save Form</span>
                    </v-tooltip>
                    <v-divider vertical class="mx-4"></v-divider>
                    <v-dialog v-model="publishDialog" width="500">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-if="formCopy.status !== 'Published'"
                                :disabled="initialPublishDisabled"
                                elevation="0"
                                class="ml-4 font-weight-600 text-capitalize btn-primary rounded-xs"
                                color="#6F70FF"
                                v-bind="attrs"
                                v-on="on">
                                Publish
                            </v-btn>
                            <v-btn v-else elevation="0" class="ml-4 font-weight-600 text-capitalize btn-success rounded-xs" color="#2dce89"> Published </v-btn>
                        </template>

                        <v-card>
                            <v-card-title class="text-h2 font-weight-600 pa-4 mb-0 mb-0 text-center">Publish - {{ formCopy.name }} </v-card-title>
                            <v-divider></v-divider>

                            <v-card-text class="pa-4">
                                <div v-if="formCopy.schedule" class="label-color font-weight-600 subtitle-2 d-block mb-2 d-flex align-center">
                                    Schedule:
                                    <schedule-menu :form="formCopy"></schedule-menu>
                                    <span v-if="formCopy.schedule && formCopy.schedule.type" class="text-light">{{ formCopy.schedule.type }} </span>
                                    <span v-if="formCopy.schedule && formCopy.schedule.releaseDate" class="text-light"
                                        >, {{ $moment(formCopy.schedule.releaseDate).format('dddd, MMMM Do YYYY, h:mm a') }}</span
                                    >
                                </div>
                                <div class="label-color font-weight-600 subtitle-2 d-block mb-2 d-flex align-center">
                                    <span>Assigned: </span>
                                    <assign-user-menu :form="formCopy"></assign-user-menu>
                                </div>
                                <p class="text-grey font-size-root font-weight-thin my-4">Once the form has been published it will be sent to the assigned person to fill in the form.</p>
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-btn
                                    elevation="0"
                                    height="43"
                                    class="font-weight-600 btn-block text-capitalize btn-ls btn-outline-primary rounded-sm me-2 my-2"
                                    color="transparent"
                                    @click="publishDialog = false"
                                    >Cancel</v-btn
                                >
                                <v-btn
                                    elevation="0"
                                    :disabled="finalPublishDisabled"
                                    height="43"
                                    class="font-weight-600 btn-block text-capitalize btn-primary rounded-sm"
                                    color="#6F70FF"
                                    @click="publishForm()"
                                    >Publish Now</v-btn
                                >
                            </v-card-actions>
                        </v-card>
                        <v-overlay :value="isLoading" absolute>
                            <v-progress-circular indeterminate size="32"></v-progress-circular>
                        </v-overlay>
                    </v-dialog>
                </div>
            </div>
        </v-card>
        <div class="d-flex flex-row mt-6 form-editor-container">
            <FormEditorSidebar :form="formCopy" class="form-editor-sidebar" />
            <div class="form-editor-page">
                <slide-x-left-transition :duration="200" origin="center top" mode="out-in">
                    <router-view :form="formCopy" :formValid.sync="formValid"></router-view>
                </slide-x-left-transition>
            </div>
        </div>
    </div>
</template>

<script>
import FormEditorSidebar from '@/components/FormEditorSidebar.vue';
import ScheduleMenu from './components/Menus/ScheduleMenu.vue';
import AssignUserMenu from './components/Menus/AssignUserMenu.vue';
import { SlideXLeftTransition } from 'vue2-transitions';
import { makeGetMixin } from 'feathers-vuex';
import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
        FormEditorSidebar,
        ScheduleMenu,
        AssignUserMenu,
        SlideXLeftTransition
    },
    mixins: [
        makeGetMixin({
            service: 'forms',
            name: 'form',
            id: 'formId',
            queryWhen: 'formId',
            watch: true
        })
    ],
    data() {
        return {
            publishDialog: false,
            formCopy: {},
            initialForm: {},
            saveDialog: false,
            formValid: true,
            resolve: null,
            reject: null,
            isLoading: false,
            qrIcon: require('@/assets/img/illustrations/qrcode-solid.svg'),
            isGeneratingPDF: false
        };
    },
    methods: {
        async saveForm() {
            if (!this.formValid) {
                this.showError('Some of the required fields have not been filled in.');
                return;
            }

            try {
                if (this.formCopy.status !== 'Published') {
                    this.formCopy.status = 'Draft';
                }

                await this.formCopy.save();
                this.initialForm = JSON.parse(JSON.stringify(this.formCopy));
                this.showSuccess('Successfully saved form!');
            } catch (error) {
                this.showError(error);
            }
        },
        async publishForm() {
            this.isLoading = true;

            if (!this.formValid) {
                this.showError('Some of the required fields have not been filled in.');
                return;
            }

            try {
                this.formCopy.status = 'Published';
                await this.formCopy.save();
                this.publishDialog = false;
                this.initialForm = JSON.parse(JSON.stringify(this.formCopy));
                this.isLoading = false;
                this.$router.push({ path: '/form-manager' });
                this.showSuccess('Successfully published form!');
            } catch (error) {
                this.isLoading = false;
                this.showError(error);
            }
        },
        async pdfGeneration(type) {
            this.isGeneratingPDF = true;
            let result = null;
            try {
                switch (type) {
                    case 'empty-form':
                        result = await this.$store.dispatch('reporting-empty/create', {
                            form_id: this.formCopy.id
                        });
                        break;
                    case 'form_access_qr':
                        result = await this.$store.dispatch('reporting-qr-single/create', {
                            qr_type: 'form_access_qr',
                            form_id: this.formCopy.id
                        });
                        break;
                }
                this.isGeneratingPDF = false;
                if (result?.url) {
                    window.open(`${this.$feathersConnectionString}${result.url}`, '_blank').focus();
                }
            } catch (error) {
                this.showError(error);
                this.isGeneratingPDF = false;
            }
        },
        hideForm() {
            this.formCopy.hide = !this.formCopy.hide;
        },
        openSaveDialog() {
            this.saveDialog = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        confirmSave() {
            this.resolve(true);
            this.saveDialog = false;
        },
        cancelSave() {
            this.resolve(false);
            this.saveDialog = false;
        },
        userInitials(user) {
            const initials = (user.first_name + ' ' + user.last_name).match(/\b\w/g) || [];

            return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        },
        ...mapActions('app', ['showSuccess', 'showError'])
    },
    computed: {
        usersParams() {
            return {
                query: {
                    $sort: true
                }
            };
        },
        formId() {
            return this.$route.params && this.$route.params.id;
        },
        assigneeId() {
            if (this.formCopy.assignee_id) {
                return this.formCopy.assignee_id;
            }

            return '';
        },
        formNameDisabled() {
            if (this.formCopy.name && this.formCopy.name.length) {
                return false;
            } else {
                return true;
            }
        },
        initialPublishDisabled() {
            if (this.formCopy.sections && this.formCopy.sections) {
                if (this.formCopy.name.length && this.formCopy.sections.length) {
                    return false;
                } else {
                    return true;
                }
            }

            return true;
        },
        finalPublishDisabled() {
            if (!this.formCopy.assignees) {
                return false;
            }

            if (this.formCopy.assignees && this.formCopy.assignees.length) {
                return false;
            } else {
                return true;
            }
        },
        ...mapGetters('apiAuth', { authUser: 'user' })
    },
    async beforeRouteLeave(to, from, next) {
        if (this.formNameDisabled) {
            next();
        }

        if (Object.entries(this.initialForm).toString() !== Object.entries(this.formCopy).toString()) {
            if (await this.openSaveDialog()) {
                this.saveForm();
                next();
            }
        }

        next();
    },
    watch: {
        isGetFormPending(pending) {
            if (!pending && this.form) {
                this.formCopy = this.form;
                this.initialForm = JSON.parse(JSON.stringify(this.formCopy));
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.form-editor-page-wrapper {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;

    .form-editor-container {
        height: 100%;

        .form-editor-sidebar {
            flex-grow: 0;
            min-width: 90px;
            max-width: 90px;
        }
        .form-editor-page {
            flex-grow: 1;
        }
    }
}
</style>
