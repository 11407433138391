<template>
    <v-card class="mr-6 d-flex justify-space-between flex-column card-shadow account-sidebar" width="90">
        <div class="d-flex flex-column align-center">
            <v-tooltip right v-for="(item, index) in editorItemsFilter" :key="index">
                <template v-slot:activator="{ on, attrs }">
                    <router-link :to="formId ? `${item.link}/${formId}` : `${item.link}`" class="mt-6" :class="{ active: false }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <feather :type="item.icon" class="text-darker" stroke-width="1.5"></feather>
                        </v-btn>
                    </router-link>
                </template>
                <span>{{ item.name }}</span>
            </v-tooltip>
        </div>
        <!-- <div class="d-flex flex-column align-center">
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" class="mb-6">
                        <feather type="help-circle" class="text-darker" stroke-width="1.5"></feather>
                    </v-btn>
                </template>
                <span>Help</span>
            </v-tooltip>
        </div> -->
    </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "AccountSidebar",
    props: {
        form: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            drawer: null,
            editorItems: [
                {
                    name: "Form Builder",
                    icon: "edit",
                    link: "/edit-form"
                },
                {
                    name: "Form Preview",
                    icon: "eye",
                    link: "/preview-form"
                }
            ]
        };
    },
    computed: {
        formId() {
            return this.$route.params && this.$route.params.id
        },
        editorItemsFilter() {
            if (this.form.status === 'Published') {
                return this.editorItems.filter((el) => {
                    return el.name != 'Form Builder'
                })
            }

            return this.editorItems
        }
    },
    methods: {
        ...mapActions("apiAuth", { authLogout: "logout" }),
        ...mapActions("app", ["showSuccess", "showError"])
    }
};
</script>

<style lang="scss" scoped>
.router-link-exact-active button {
    background-color: #F3F4FD;
}

.account-sidebar {
    // position: fixed;
    // max-height: 75vh;
    display: flex;
    height: 100%;
    flex-grow: 0;
}
</style>
