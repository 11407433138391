<template>
    <v-menu v-if="form.schedule" v-model="showMenu" :close-on-click="false" transition="slide-y-transition" offset-y :close-on-content-click="false" right>
        <template #activator="menu">
            <v-tooltip bottom>
                <template #activator="tooltip">
                    <v-btn class="ml-4" icon v-on="{ ...menu.on, ...tooltip.on }">
                        <v-avatar size="33" :color="form.schedule.type !== 'None' ? '#B7B8FF' : '#F3F4FD'">
                            <feather type="calendar" class="text-darker" size="20" stroke-width="1.5"></feather>
                        </v-avatar>
                    </v-btn>
                </template>
                <span>Schedule</span>
            </v-tooltip>
        </template>

        <v-list min-width="480" max-width="480" class="pt-0 schedule" three-line>
            <div class="card-title-wrapper pa-8">
                <v-row>
                    <v-col cols="12" class="d-flex align-center py-0 justify-space-between">
                        <v-card-title class="text-h2 font-weight-600 pa-0 mb-0 text-center">Form Schedule Reminder</v-card-title>
                        <v-btn @click="closeMenu()" icon>
                            <v-avatar size="33" color="'#F3F4FD'">
                                <feather type="x" class="text-darker" size="20" stroke-width="1.5"></feather>
                            </v-avatar>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
            <v-form ref="form">
                <v-row class="pa-8">
                    <v-col cols="12" class="pt-0">
                        <label for="recurring" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Schedule Type</label>
                        <v-select
                            hide-details="auto"
                            @input="scheduleType"
                            id="recurring"
                            :items="scheduleTypes"
                            v-model="form.schedule.type"
                            item-text="value"
                            item-value="value"
                            single-line
                            outlined
                            class="input-style select-style text-color-gray"
                            placeholder="Select option">
                        </v-select>
                    </v-col>
                    <v-col v-if="form.schedule.type !== 'None'" cols="6">
                        <label for="endDate" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Release Date</label>
                        <v-menu id="endDate" v-model="releaseDayMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="releaseDay"
                                    outlined
                                    class="input-style font-size-input text-color-gray"
                                    v-bind="attrs"
                                    v-on="on"
                                    placeholder="Release Date"
                                    :rules="[rules.required]"
                                    hide-details="auto"
                                    @focus="releaseDay = releaseDay || null">
                                    <template slot="append">
                                        <v-btn @click="releaseDayMenu = !releaseDayMenu" :ripple="false" icon max-height="0.875rem">
                                            <feather type="calendar" class="text-darker" size="18" stroke-width="1.5"></feather>
                                        </v-btn>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-date-picker
                              required
                              v-model="releaseDay"
                              no-title
                              scrollable
                              color="#6F70FF"
                              :min="todayDate"
                            >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="releaseDayMenu = false"> Cancel </v-btn>
                                <v-btn text color="primary" @click="releaseDayMenu = false"> OK </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col v-if="form.schedule.type !== 'None' && form.schedule.releaseDate.length" cols="6">
                        <label class="label-color font-weight-600 subtitle-2 mb-2 d-block">Time</label>
                        <v-menu v-model="releaseTimeMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="releaseTime"
                                    hide-details="auto"
                                    outlined
                                    class="input-style font-size-input text-color-gray"
                                    v-bind="attrs"
                                    v-on="on"
                                    placeholder="Select time"
                                    readonly>
                                    <template slot="append">
                                        <v-btn @click="releaseTimeMenu = !releaseTimeMenu" :ripple="false" icon max-height="0.875rem">
                                            <feather type="clock" class="text-darker" size="18" stroke-width="1.5"></feather>
                                        </v-btn>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-time-picker
                              v-model="releaseTime"
                              full-width
                              color="#6F70FF"
                              format="24hr"
                              :min="currentTime"
                              @click:minute="releaseTime = false"
                            ></v-time-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-form>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    props: {
        form: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showMenu: false,
            releaseDayMenu: false,
            releaseTimeMenu: false,
            errors: [],
            scheduleTypes: [{ value: 'None' }, { value: 'Once-off' }, { value: 'Daily' }, { value: 'Weekly' }, { value: 'Monthly' }],
            rules: {
                required: (value) => (value && Boolean(value)) || '*This field is required'
            }
        };
    },
    computed: {
        todayDate() {
            return this.$moment().format('YYYY-MM-DD');
        },
        currentTime() {
            if (this.$moment().isBefore(this.releaseDay, this.$moment().format('YYYY-MM-DD'))) {
                return '';
            } else {
                return this.$moment().format('HH:mm');
            }
        },
        releaseTime: {
            get() {
                if (!this.form.schedule.releaseDate) {
                    return '';
                }

                return this.$moment(this.form.schedule.releaseDate).format('HH:mm');
            },
            set(val) {
                const hoursMinutes = val.split(':');
                const hour = parseInt(hoursMinutes[0]);
                const min = parseInt(hoursMinutes[1]);

                const newTime = this.$moment().set({ hour: hour, minute: min }).format('HH:mm');
                this.setDateAndTime(this.releaseDay, newTime);
            }
        },
        releaseDay: {
            get() {
                if (!this.form.schedule.releaseDate) {
                    return '';
                }

                return this.$moment(this.form.schedule.releaseDate).format('YYYY-MM-DD');
            },
            set(val) {
                const date = val.split('-');
                const year = parseInt(date[0]);
                const month = parseInt(date[1]);
                const day = parseInt(date[2]);

                const newDate = this.$moment({ Y: year, M: month - 1, D: day }).format('YYYY-MM-DD');
                this.setDateAndTime(newDate, this.releaseTime);
            }
        }
    },
    methods: {
        closeMenu() {
            if (this.$refs.form.validate()) {
                this.showMenu = false;
            }
        },
        setDateAndTime(date, time) {
            this.form.schedule.releaseDate = this.$moment(date + ' ' + time).toISOString();
        },
        scheduleType(evt) {
            if (evt === 'None') {
                this.form.schedule.releaseDate = '';
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.schedule {
    overflow: hidden !important;
}
</style>
