<template>
    <div>
        <v-card-title class="text-h1 font-weight-600 pa-0 mb-4">Form Manager</v-card-title>
        <v-card-subtitle class="text-subtitle-1 pa-0 mb-4">The form manager allows you to create and share new forms as well as view the submitted forms</v-card-subtitle>
        <v-card class="card-shadow pa-4">
            <div class="d-md-flex justify-space-between">
                <v-tabs v-model="tab" @change="changeTab()" tabs-item-max-width="20" tabs-item-min-width="20" active-class="active">
                    <v-tabs-slider color="#6F70FF"></v-tabs-slider>

                    <v-tab href="#created-forms" class="tab">
                        <feather type="file-plus" size="20" class="mr-1" stroke-width="1.5"></feather>
                        <span class="font-weight-600">Created</span>
                    </v-tab>

                    <v-tab href="#submitted-forms" class="tab">
                        <feather type="file-text" size="20" class="mr-1" stroke-width="1.5"></feather>
                        <span class="font-weight-600">Submitted</span>
                    </v-tab>

                    <v-tab href="#form-groups" class="tab">
                        <feather type="bar-chart-2" size="20" class="mr-1" stroke-width="1.5"></feather>
                        <span class="font-weight-600">Analytics</span>
                    </v-tab>

                    <v-tab href="#hidden-forms" class="tab">
                        <feather type="eye" size="20" class="mr-1" stroke-width="1.5"></feather>
                        <span class="font-weight-600">Hidden</span>
                    </v-tab>
                </v-tabs>

                <div class="d-flex flex-row xs-margin-top">
                    <v-btn
                        @click="createNewForm()"
                        v-if="tab === 'created-forms'"
                        elevation="0"
                        height="40"
                        outlined
                        class="font-weight-600 text-capitalize btn-primary rounded-xs mr-4"
                        color="#6F70FF">
                        <feather type="plus" size="20" class="text-primary mr-1" stroke-width="1.5"></feather>
                        Create Form
                    </v-btn>
                    <v-text-field v-model="searchQuery" hide-details outlined dense class="input-style font-size-input text-color-gray mt-0 mb-4 search-input" placeholder="Search">
                        <template slot="prepend-inner">
                            <feather type="search" size=".875rem" stroke-width="1.5" color="#adb5bd" class="eye mt-1 mr-1"> </feather>
                        </template>
                    </v-text-field>

                    <v-menu transition="slide-y-transition" offset-y :position-x="50">
                        <template #activator="menu">
                            <v-tooltip bottom>
                                <template #activator="tooltip">
                                    <v-btn class="ml-4" icon v-on="{ ...menu.on, ...tooltip.on }">
                                        <v-avatar size="33" :color="filterOption ? '#B7B8FF' : '#F3F4FD'">
                                            <feather type="filter" class="text-darker" size="1.2rem" stroke-width="1.5"></feather>
                                        </v-avatar>
                                    </v-btn>
                                </template>

                                <span>Filter by</span>
                            </v-tooltip>
                        </template>

                        <v-list min-width="192" max-width="192" nav dense>
                            <v-subheader>Filters</v-subheader>
                            <v-list-item-group v-model="selectedFilter">
                                <v-list-item @click="selectFilter(item.filter)" link v-for="(item, i) in filtersList" :key="i">
                                    <v-list-item-content class="pa-0">
                                        <v-list-item-title class="text-darker">{{ item.name }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                    <v-menu ref="menu" v-model="dateMenu" :close-on-content-click="false" offset-y bottom left min-width="auto">
                        <template #activator="menu">
                            <v-tooltip bottom>
                                <template #activator="tooltip">
                                    <v-btn class="ml-4" icon v-on="{ ...menu.on, ...tooltip.on }">
                                        <v-avatar size="33" :color="dateRange.length ? '#B7B8FF' : '#F3F4FD'">
                                            <feather type="calendar" class="text-darker" size="1.2rem" stroke-width="1.5"></feather>
                                        </v-avatar>
                                    </v-btn>
                                </template>

                                <span>Filter between two dates</span>
                            </v-tooltip>
                        </template>
                        <v-date-picker v-model="dateRange" no-title scrollable range color="#6F70FF">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="clearDateRange()"> Clear </v-btn>
                            <v-btn text color="primary" @click="setDateRange()"> OK </v-btn>
                        </v-date-picker>
                    </v-menu>
                    <v-tooltip bottom>
                        <template #activator="tooltip">
                            <v-btn class="ml-4" icon v-on="{ ...tooltip.on }" @click.prevent="downloadCSV('visible')" v-show="tab === 'created-forms'">
                                <v-avatar size="33" :color="'#F3F4FD'">
                                    <feather type="download" class="text-darker" size="1.2rem" stroke-width="1.5"></feather>
                                </v-avatar>
                            </v-btn>
                        </template>

                        <span>Download Form List (Available)</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template #activator="tooltip">
                            <v-btn class="ml-4" icon v-on="{ ...tooltip.on }" @click.prevent="downloadCSV('full')" v-show="tab === 'created-forms'">
                                <v-avatar size="33" :color="'#F3F4FD'">
                                    <feather type="download" class="text-darker" size="1.2rem" stroke-width="1.5"></feather>
                                </v-avatar>
                            </v-btn>
                        </template>

                        <span>Download Form List (All)</span>
                    </v-tooltip>
                </div>
            </div>
            <v-tabs-items v-model="tab">
                <v-tab-item value="created-forms">
                    <created-forms :searchQuery="searchQuery" :filterOption="filterOption" :dateRange="dateRangeValues"></created-forms>
                </v-tab-item>
                <v-tab-item value="submitted-forms">
                    <submitted-forms :searchQuery="searchQuery" :filterOption="filterOption" :dateRange="dateRangeValues"></submitted-forms>
                </v-tab-item>
                <v-tab-item value="form-groups">
                    <form-groups :searchQuery="searchQuery" :filterOption="filterOption" :dateRange="dateRangeValues"></form-groups>
                </v-tab-item>
                <v-tab-item value="hidden-forms">
                    <hidden-forms :searchQuery="searchQuery" :filterOption="filterOption" :dateRange="dateRangeValues"></hidden-forms>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
        <!-- <pre>{{ forms }}</pre> -->
    </div>
</template>

<script>
import CreatedForms from './CreatedForms.vue';
import SubmittedForms from './SubmittedForms.vue';
import FormGroups from './FormGroups.vue';
import HiddenForms from './HiddenForms.vue';
import { models } from 'feathers-vuex';
import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
        CreatedForms,
        SubmittedForms,
        FormGroups,
        HiddenForms
    },
    data() {
        return {
            searchQuery: '',
            filterOption: '',
            selectedFilter: 'ascending',
            dateMenu: '',
            dateRange: [],
            dates: [],
            filters: [
                {
                    name: 'User',
                    filter: 'user'
                },
                {
                    name: 'Form Name (a-z)',
                    filter: 'ascending'
                },
                {
                    name: 'Form Name (z-a)',
                    filter: 'descending'
                },
                {
                    name: 'Date added (latest)',
                    filter: 'latestCreated'
                },
                {
                    name: 'Date added (oldest)',
                    filter: 'oldestCreated'
                },
                {
                    name: 'Date updated (latest)',
                    filter: 'latestUpdated'
                },
                {
                    name: 'Date updated (oldest)',
                    filter: 'oldestUpdated'
                }
            ]
        };
    },
    methods: {
        async downloadCSV(type) {
            let result = await this.$store.dispatch('formlist/create', {
                type
            });
            if (result?.url) {
                window.open(`${this.$feathersConnectionString}${result.url}`, '_blank').focus();
            }
        },
        async createNewForm() {
            try {
                const { Form } = models.api;
                const newForm = new Form();

                // newForm.name = 'Form Name'
                newForm.manager_id = this.authUser.id;
                newForm.status = 'Draft';
                newForm.schedule.type = 'None';

                const res = await newForm.save();

                this.showSuccess('A new form has been created.');
                this.$router.push({ path: `/edit-form/${res.id}` });
            } catch (error) {
                this.showError(error);
            }
        },
        changeTab() {
            this.dateRange = [];
        },
        selectFilter(filterOption) {
            this.filterOption = filterOption;
            this.selectedFilter = filterOption.toString();
        },
        setDateRange() {
            this.dateMenu = false;
        },
        clearDateRange() {
            this.dateRange = [];
        },
        ...mapActions('app', ['showSuccess', 'showError'])
    },
    computed: {
        filtersList() {
            if (this.tab !== 'created-forms') {
                return this.filters;
            } else {
                return this.filters.slice(1);
            }
        },
        dateRangeValues() {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            return this.dateRange.sort((a, b) => new Date(a) - new Date(b)).map((d) => this.$moment(d).format('YYYY-MM-DD'));
        },
        tab: {
            set(tab) {
                this.$router.replace({ query: { ...this.$route.query, tab } });
            },
            get() {
                return this.$route.query.tab;
            }
        },
        ...mapGetters('apiAuth', { authUser: 'user' })
    }
};
</script>
<style lang="scss" scoped>
.active {
    color: #6f70ff !important;

    i {
        color: #6f70ff !important;
    }
}

.search-input {
    width: 15rem;
}

@media screen and (max-width: 540px) {
    .xs-margin-top {
        margin-top: 1rem;
    }

    .tab {
        padding: 0 10px 0 0;
    }
}

@media screen and (max-width: 960px) {
    .xs-margin-top {
        margin-top: 1rem;
    }

    .tab {
        padding: 0 20px 0 0;
    }
}
</style>
