<template>
    <div class="form-editor-page-wrapper">
        <v-card class="card-shadow pa-4">
            <div class="d-flex justify-space-between xs-wrap">
                <div class="d-flex flex-row align-center flex-grow-1">
                    <v-btn @click="navigateBack()" icon elevation="0" class="rounded-xs mr-2" color="#6F70FF">
                        <feather type="chevron-left" class="text-darker" stroke-width="1.5"></feather>
                    </v-btn>
                    <v-text-field class="text-h2 font-weight-600" v-model="submissionCopy.name" :readonly="true" solo flat placeholder="Form Name" hide-details="auto" dense auto-grow></v-text-field>
                </div>
                <div class="d-flex align-center xs-margin-top xs-section-wrap">
                    <div>
                        <v-tooltip
                            v-if="
                                authUser &&
                                (((authUser.role === 'Manager' || authUser.role === 'Employee') && authUser.id === submissionCopy.manager_id) ||
                                    authUser.role === 'GlobalManager' ||
                                    authUser.role === 'Admin') &&
                                submissionCopy.status === 'Submitted'
                            "
                            bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn @click="verifyForm()" v-if="submissionId && !submissionCopy.verified" class="ml-4" icon v-bind="attrs" v-on="on">
                                    <v-avatar size="33" color="#F3F4FD">
                                        <feather type="check-circle" class="text-darker" size="20" stroke-width="1.5"></feather>
                                    </v-avatar>
                                </v-btn>
                                <v-btn v-else class="ml-4" icon v-bind="attrs" v-on="on">
                                    <v-avatar size="33" color="#2DCE89">
                                        <feather type="check-circle" class="text-white" size="20" stroke-width="1.5"></feather>
                                    </v-avatar>
                                </v-btn>
                            </template>
                            <span v-if="submissionId && !submissionCopy.verified">Verify Form</span>
                            <span v-else>Form Verified</span>
                        </v-tooltip>
                        <v-tooltip v-if="submissionId && submissionCopy.status === 'Submitted'" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn @click="pdfGeneration('form-results')" class="ml-4" icon v-bind="attrs" v-on="on" :disabled="isGeneratingPDF" :loading="isGeneratingPDF">
                                    <v-avatar size="33" color="#F3F4FD">
                                        <feather type="download" class="text-darker" size="20" stroke-width="1.5"></feather>
                                    </v-avatar>
                                </v-btn>
                            </template>
                            <span>Download Form Results</span>
                        </v-tooltip>
                        <v-tooltip v-if="submissionCopy.status !== 'Submitted'" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn @click="pdfGeneration('empty-form')" class="ml-4" icon v-bind="attrs" v-on="on" :disabled="isGeneratingPDF" :loading="isGeneratingPDF">
                                    <v-avatar size="33" color="#F3F4FD">
                                        <feather type="download" class="text-darker" size="20" stroke-width="1.5"></feather>
                                    </v-avatar>
                                </v-btn>
                            </template>
                            <span>Download Empty Form</span>
                        </v-tooltip>
                        <v-tooltip v-if="submissionCopy.status !== 'Submitted'" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn @click="pdfGeneration('form_access_qr')" class="ml-4" icon v-bind="attrs" v-on="on" :disabled="isGeneratingPDF" :loading="isGeneratingPDF">
                                    <v-avatar size="33" color="#F3F4FD">
                                        <v-img :src="qrIcon" max-height="15" contain />
                                    </v-avatar>
                                </v-btn>
                            </template>
                            <span>Download Form QR Code</span>
                        </v-tooltip>
                        <v-tooltip v-if="submissionCopy.status !== 'Submitted'" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn @click="saveForm()" class="ml-4" icon v-bind="attrs" v-on="on">
                                    <v-avatar size="33" color="#F3F4FD">
                                        <feather type="save" class="text-darker" size="20" stroke-width="1.5"></feather>
                                    </v-avatar>
                                </v-btn>
                            </template>
                            <span>Save</span>
                        </v-tooltip>
                        <v-dialog v-model="saveDialog" width="500">
                            <v-card>
                                <v-card-title class="text-h2 font-weight-600 pa-4 mb-0 mb-0 text-center">Save Form</v-card-title>
                                <v-divider></v-divider>

                                <v-card-text class="pa-4">
                                    <p class="text-grey font-size-root font-weight-thin my-4">Are you sure you want to leave your changes unsaved?</p>
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                    <v-btn
                                        elevation="0"
                                        height="43"
                                        class="font-weight-600 btn-block text-capitalize btn-ls btn-outline-primary rounded-sm me-2 my-2"
                                        color="transparent"
                                        @click.native="cancelSave"
                                        >Discard Changes</v-btn
                                    >
                                    <v-btn elevation="0" height="43" class="font-weight-600 btn-block text-capitalize btn-primary rounded-sm" color="#6F70FF" @click.native="confirmSave"
                                        >Save and Exit</v-btn
                                    >
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="isOffline" width="500">
                            <v-card>
                                <v-card-title class="text-h2 font-weight-600 pa-4 mb-0 mb-0 text-center">Network Connectivity</v-card-title>
                                <v-divider></v-divider>

                                <v-card-text class="pa-4">
                                    <p class="text-grey font-size-root font-weight-thin my-4">Your network is currently offline. Your changes will be saved once you are back online.</p>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                        <v-tooltip v-if="submissionCopy.status !== 'Submitted' && isMobile" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn @click="transferDialog = true" class="ml-4 xs-display" icon v-bind="attrs" v-on="on">
                                    <v-avatar size="33" color="#F3F4FD">
                                        <feather type="user-plus" class="text-darker" size="20" stroke-width="1.5"></feather>
                                    </v-avatar>
                                </v-btn>
                            </template>
                            <span>Transfer</span>
                        </v-tooltip>
                        <v-tooltip v-if="submissionCopy.status !== 'Submitted' && isMobile" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn @click="submitDialog = true" class="ml-4 xs-display" icon v-bind="attrs" v-on="on">
                                    <v-avatar size="33" color="#F3F4FD">
                                        <feather type="send" class="text-darker" size="20" stroke-width="1.5"></feather>
                                    </v-avatar>
                                </v-btn>
                            </template>
                            <span>Submit</span>
                        </v-tooltip>
                    </div>

                    <v-divider vertical class="mx-4"></v-divider>
                    <div class="">
                        <v-dialog v-if="submissionCopy.status !== 'Submitted'" v-model="transferDialog" width="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn elevation="0" outlined class="ml-4 font-weight-600 text-capitalize btn-primary rounded-xs" color="#6F70FF" v-bind="attrs" v-on="on"> Transfer </v-btn>
                            </template>

                            <v-card>
                                <v-card-title class="text-h2 font-weight-600 pa-4 mb-0 mb-0 text-center">Transfer Form</v-card-title>
                                <v-divider></v-divider>

                                <v-card-text class="pa-4">
                                    <div class="label-color font-weight-600 subtitle-2 d-block mb-2 d-flex align-center">
                                        <span>Assign to: </span>
                                        <v-menu transition="slide-y-transition" offset-y :position-x="50">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn class="ml-4" icon v-bind="attrs" v-on="on">
                                                    <v-avatar size="33" color="#F3F4FD">
                                                        <feather v-if="!userTransfer" type="user-plus" class="text-darker" size="20" stroke-width="1.5"></feather>
                                                        <span v-else-if="!userTransfer.avatar" class="text-primary text-h5 font-weight-600">{{ userInitials(userTransfer) }}</span>
                                                        <img v-else :src="`${$feathersConnectionString}/${userTransfer.avatar.url}`" alt="User" />
                                                    </v-avatar>
                                                </v-btn>
                                            </template>

                                            <v-list min-width="220" max-width="220" nav dense>
                                                <v-list-item @click="assignUser(user)" link v-for="(user, index) in users" :key="index">
                                                    <v-list-item-avatar color="#F3F4FD" class="my-0" width="37" min-width="15" height="37">
                                                        <img v-if="user.avatar" :src="`${$feathersConnectionString}/${user.avatar.url}`" alt="User" />
                                                        <span v-else class="text-primary text-h5 ml-3 font-weight-600">{{ userInitials(user) }}</span>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content class="pa-0">
                                                        <v-list-item-title class="text-darker">{{ user.first_name }} {{ user.last_name }}</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                    <p v-if="!userTransfer" class="text-grey font-size-root font-weight-thin my-4">You can transfer the form to someone else</p>
                                    <p v-else class="text-grey font-size-root font-weight-thin my-4">
                                        Your form will be transferred to <span class="font-weight-600">{{ userTransfer.first_name }} {{ userTransfer.last_name }}.</span>
                                    </p>
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                    <v-btn
                                        elevation="0"
                                        height="43"
                                        class="font-weight-600 btn-block text-capitalize btn-ls btn-outline-primary rounded-sm me-2 my-2"
                                        color="transparent"
                                        @click="
                                            transferDialog = false;
                                            userTransfer = null;
                                        "
                                        >Cancel</v-btn
                                    >
                                    <v-btn
                                        elevation="0"
                                        :disabled="!userTransfer"
                                        height="43"
                                        class="font-weight-600 btn-block text-capitalize btn-primary rounded-sm"
                                        color="#6F70FF"
                                        @click="transferForm()"
                                        >Transfer Form</v-btn
                                    >
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-if="submissionCopy.status !== 'Submitted'" v-model="submitDialog" width="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn elevation="0" class="ml-4 font-weight-600 text-capitalize btn-primary rounded-xs" color="#6F70FF" v-bind="attrs" v-on="on"> Submit </v-btn>
                            </template>

                            <v-card>
                                <v-card-title class="text-h2 font-weight-600 pa-4 mb-0 mb-0 text-center">Form submission</v-card-title>
                                <v-divider></v-divider>

                                <v-card-text class="pa-4">
                                    <p class="text-grey font-size-root font-weight-thin my-4">Are you sure you want to submit this form?</p>
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                    <v-btn
                                        elevation="0"
                                        height="43"
                                        class="font-weight-600 btn-block text-capitalize btn-ls btn-outline-primary rounded-sm me-2 my-2"
                                        color="transparent"
                                        @click="submitDialog = false"
                                        >Cancel</v-btn
                                    >
                                    <v-btn elevation="0" height="43" class="font-weight-600 btn-block text-capitalize btn-primary rounded-sm" color="#6F70FF" @click="submitForm()">Submit Now</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-btn v-else elevation="0" class="ml-4 font-weight-600 text-capitalize btn-primary rounded-xs" color="#4FD69C"> Submitted </v-btn>
                    </div>
                </div>
            </div>
        </v-card>
        <div v-if="submissionCopy.sections" class="d-flex flex-row mt-6 form-editor-container">
            <div class="form-editor-page">
                <v-form ref="form">
                    <v-row class="d-flex justify-center">
                        <v-col cols="12" sm="10" md="10" lg="10" xl="8" class="pt-3">
                            <v-card class="card-shadow">
                                <div class="card-title-wrapper pa-6 py-4">
                                    <div class="d-flex align-center justify-space-between mb-2">
                                        <span>
                                            <v-card-title class="text-h1 font-weight-600 mb-6 pa-0">{{ submissionCopy.name }} - {{ submissionCopy.sections[currentPage - 1].title }} </v-card-title>
                                            <v-card-subtitle class="text-subtitle-2 text-light subtitle-2 pa-0">{{ submissionCopy.sections[currentPage - 1].description }} </v-card-subtitle>
                                        </span>
                                        <v-btn v-if="sectionQRCount > 0" @click="bulkPDF()" class="download-btn" icon :disabled="isGeneratingPDF" :loading="isGeneratingPDF">
                                            <v-img :src="qrIcon" max-height="16" contain />
                                        </v-btn>
                                    </div>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col v-for="(question, index) in submissionCopy.sections[currentPage - 1].questions" :key="index" cols="12" sm="10" md="10" lg="10" xl="8">
                            <v-card class="card-shadow">
                                <div class="card-title-wrapper pa-6">
                                    <div class="d-flex justify-space-between mb-2 title-section">
                                        <label class="label-color font-weight-600 subtitle-2 d-block">{{ index + 1 }}. {{ question.name }}</label>
                                        <div class="d-flex align-center">
                                            <div v-if="question.type === 'Text'">
                                                <v-dialog v-if="submissionCopy.status !== 'Submitted'" v-model="qrCodeDialog" max-width="600px">
                                                    <template #activator="dialog">
                                                        <v-tooltip v-if="submissionCopy.status !== 'Submitted'" bottom>
                                                            <template #activator="tooltip">
                                                                <v-btn class="download-btn" icon v-on="{ ...dialog.on, ...tooltip.on }">
                                                                    <feather type="smartphone" class="text-darker" size="20" stroke-width="1.5"></feather>
                                                                </v-btn>
                                                            </template>
                                                            <span>Scan QR Code</span>
                                                        </v-tooltip>
                                                    </template>

                                                    <qrcode-stream
                                                        v-if="question.type === 'Text' && qrCodeDialog"
                                                        :camera="camera"
                                                        @decode="onDecode($event, question)"
                                                        :track="selected.value"
                                                        @init="onInit"></qrcode-stream>
                                                </v-dialog>
                                            </div>
                                            <v-tooltip v-if="question.guide.length && submissionCopy.status !== 'Submitted'" bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <feather type="info" class="text-primary" size="16" stroke-width="1.5" v-bind="attrs" v-on="on"></feather>
                                                </template>
                                                <span>{{ question.guide }}</span>
                                            </v-tooltip>
                                            <v-fade-transition v-if="question.qrLabel.length">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            v-if="question.qrLabel === 'Print Label and QR code'"
                                                            @click="pdfGeneration('label_and_qr', question.id)"
                                                            class="download-btn"
                                                            icon
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            :disabled="isGeneratingPDF"
                                                            :loading="isGeneratingPDF">
                                                            <v-img :src="qrIcon" max-height="16" contain />
                                                        </v-btn>
                                                        <v-btn
                                                            v-if="question.qrLabel === 'Print Label without QR code'"
                                                            @click="pdfGeneration('label_not_qr', question.id)"
                                                            class="download-btn"
                                                            icon
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            :disabled="isGeneratingPDF"
                                                            :loading="isGeneratingPDF">
                                                            <v-img :src="qrIcon" max-height="16" contain />
                                                        </v-btn>
                                                        <v-btn
                                                            v-if="question.qrLabel === 'Print QR code without label'"
                                                            @click="pdfGeneration('qr_not_label', question.id)"
                                                            class="download-btn"
                                                            icon
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            :disabled="isGeneratingPDF"
                                                            :loading="isGeneratingPDF">
                                                            <v-img :src="qrIcon" max-height="16" contain />
                                                        </v-btn>
                                                    </template>
                                                    <span>Print QR Code</span>
                                                </v-tooltip>
                                            </v-fade-transition>
                                        </div>
                                    </div>
                                    <div v-if="question.image" class="question-image">
                                        <img v-if="question.image" :src="`${$feathersConnectionString}/${question.image.url}`" alt="Image" class="mb-8" />
                                    </div>
                                    <v-text-field
                                        v-if="question.type === 'Text'"
                                        :readonly="submissionCopy.status === 'Submitted'"
                                        v-model="question.answer"
                                        @change="answerQuestion($event, question)"
                                        :rules="[rules.required]"
                                        autocomplete="off"
                                        hide-details="auto"
                                        outlined
                                        class="input-style font-size-input text-color-gray mt-0"
                                        placeholder="Your answer">
                                    </v-text-field>
                                    <v-select
                                        v-if="question.type === 'Dropdown'"
                                        :readonly="submissionCopy.status === 'Submitted'"
                                        :items="question.options"
                                        v-model="question.answer"
                                        @change="answerQuestion($event, question)"
                                        item-text="value"
                                        single-line
                                        :rules="[rules.required]"
                                        autocomplete="off"
                                        hide-details="auto"
                                        outlined
                                        class="input-style select-style text-color-gray"
                                        placeholder="Select option">
                                    </v-select>
                                    <div v-if="question.type === 'Checkbox'">
                                        <v-checkbox
                                            v-for="(option, index) in question.options"
                                            :key="index"
                                            :readonly="submissionCopy.status === 'Submitted'"
                                            color="#6F70FF"
                                            class="my-1 checkbox-custom checkbox-thinner"
                                            :items="question.options"
                                            v-model="question.answer"
                                            @change="answerQuestion($event, question)"
                                            :value="option.value"
                                            :hide-details="index != question.options.length - 1"
                                            multiple
                                            :rules="[() => (question && question.answer && question.answer.length > 0) || 'At least one item should be selected']">
                                            <template v-slot:label>
                                                <span class="text-body-2 ls-0">{{ option.value }}</span>
                                            </template>
                                        </v-checkbox>
                                    </div>
                                    <v-radio-group
                                        v-if="question.type === 'Single Choice'"
                                        v-model="question.answer"
                                        @change="answerQuestion($event, question)"
                                        :readonly="submissionCopy.status === 'Submitted'"
                                        hide-details="auto"
                                        :rules="[() => (question && question.answer && question.answer.length > 0) || 'At least one item should be selected']">
                                        <v-radio v-for="(option, index) in question.options" :key="index" color="#6F70FF" :value="option.value" class="checkbox-custom">
                                            <template v-slot:label>
                                                <span class="text-body-2 ls-0">{{ option.value }}</span>
                                            </template>
                                        </v-radio>
                                    </v-radio-group>
                                    <Uploader
                                        class="image"
                                        v-if="question.type === 'Image'"
                                        @onRemove="saveSubmissionOnImageRemoval"
                                        v-model="question.answer"
                                        width="200px"
                                        :accepts="['image/png', 'image/jpeg', 'image/jpg', 'image/webp']"
                                        :maxSize="4194304"
                                        :context="'general'"
                                        :disabled="submissionCopy.status === 'Submitted'" />

                                    <Uploader
                                        class="image"
                                        v-if="question.type === 'File'"
                                        @onRemove="saveSubmissionOnImageRemoval"
                                        v-model="question.answer"
                                        width="200px"
                                        :accepts="[
                                            'application/pdf',
                                            'text/csv',
                                            'application/msword',
                                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                            'application/json',
                                            'application/vnd.oasis.opendocument.spreadsheet',
                                            'application/vnd.oasis.opendocument.text',
                                            'application/vnd.oasis.opendocument.presentation',
                                            'application/vnd.ms-powerpoint',
                                            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                                            'application/rtf',
                                            'text/plain',
                                            'application/vnd.ms-excel',
                                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                            'application/xml'
                                        ]"
                                        :maxSize="4194304"
                                        :context="'general'"
                                        :disabled="submissionCopy.status === 'Submitted'" />
                                    <v-btn
                                        @click="downloadFile(question.answer)"
                                        v-if="question.type === 'File' || question.type === 'Image'"
                                        elevation="0"
                                        height="43"
                                        class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm save-btn mt-4"
                                        color="#6F70FF">
                                        Download File
                                    </v-btn>
                                    <v-menu
                                        v-if="question.type === 'Date'"
                                        :close-on-content-click="true"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                        :disabled="submissionCopy.status === 'Submitted'">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :readonly="submissionCopy.status === 'Submitted'"
                                                outlined
                                                v-model="question.answer"
                                                class="input-style font-size-input text-color-gray"
                                                v-bind="attrs"
                                                v-on="on"
                                                placeholder="Select date"
                                                :rules="[rules.required]"
                                                hide-details="auto"
                                                @blur="question.answer = question.answer || null">
                                                <template slot="append">
                                                    <v-btn :ripple="false" icon max-height="0.875rem">
                                                        <feather type="calendar" class="text-darker" size="18" stroke-width="1.5"></feather>
                                                    </v-btn>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker no-title scrollable color="#6F70FF" v-model="question.answer" @change="answerQuestion($event, question)"> </v-date-picker>
                                    </v-menu>
                                    <v-row v-if="question.type === 'Formula'" class="mb-2">
                                        <v-col v-for="(option, j) in question.options" cols="6" :key="j" class="pb-0">
                                            <label v-if="option.value.length" class="label-color font-weight-600 subtitle-2 mb-2 d-block">{{ question.options[j].value }}</label>
                                            <label v-else class="label-color font-weight-600 subtitle-2 mb-2 d-block">Value</label>
                                            <v-text-field
                                                :type="question.format === 'Text' ? 'text' : 'number'"
                                                :value="question.answer ? question.answer[j] : ''"
                                                :readonly="submissionCopy.status === 'Submitted'"
                                                @change="answerQuestion($event, question, j)"
                                                :rules="j === 0 ? [rules.required] : []"
                                                hide-details="auto"
                                                outlined
                                                class="input-style font-size-input text-color-gray mt-0"
                                                placeholder="Your answer">
                                                <template slot="prepend">
                                                    <v-icon v-if="question.format === 'Number'" size="24">mdi-numeric</v-icon>
                                                    <span v-if="question.format === 'Rand'" class="font-weight-600 subtitle-2 label-color">R</span>
                                                    <v-icon v-if="question.format === 'US Dollar'" size="24">mdi-currency-usd</v-icon>
                                                    <v-icon v-if="question.format === 'Euro'" size="24">mdi-currency-eur</v-icon>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                        <v-col v-if="question.formulaType === 'Sum' && question.format !== 'Text'" cols="12" class="pb-0">
                                            <label class="label-color font-weight-600 subtitle-2 mb-2 d-block">Total: {{ formulaSum(question.answer) }}</label>
                                        </v-col>
                                        <v-col v-if="question.formulaType === 'Average' && question.format !== 'Text'" cols="12" class="pb-0">
                                            <label class="label-color font-weight-600 subtitle-2 mb-2 d-block">Average: {{ formulaAverage(question.answer) }}</label>
                                        </v-col>
                                        <v-col v-if="question.formulaType === 'Min' && question.format !== 'Text'" cols="12" class="pb-0">
                                            <label class="label-color font-weight-600 subtitle-2 mb-2 d-block">Min: {{ formulaMin(question.answer) }}</label>
                                        </v-col>
                                        <v-col v-if="question.formulaType === 'Max' && question.format !== 'Text'" cols="12" class="pb-0">
                                            <label class="label-color font-weight-600 subtitle-2 mb-2 d-block">Max: {{ formulaMax(question.answer) }}</label>
                                        </v-col>
                                    </v-row>
                                    <div v-if="question.type === 'Signature'" class="container">
                                        <div v-if="submissionCopy.status === 'Submitted'" class="d-flex justify-center">
                                            <img :src="question.answer" width="320px" height="240px" />
                                        </div>
                                        <div v-if="submissionCopy.status !== 'Submitted'" class="container">
                                            <VueSignaturePad
                                                class="signature"
                                                width="320px"
                                                height="240px"
                                                :ref="`signaturePad_${currentPage - 1}_${index}`"
                                                :key="`signaturePad_${currentPage - 1}_${index}`"
                                                :id="`signaturePad_${currentPage - 1}_${index}`"
                                                :options="{ onEnd: () => onEnd(index, question) }" />
                                        </div>
                                        <div v-if="submissionCopy.status !== 'Submitted'" class="buttons">
                                            <v-btn
                                                elevation="0"
                                                class="font-weight-600 btn-block text-capitalize btn-ls btn-outline-primary rounded-sm"
                                                color="transparent"
                                                @click="clear(question, index)">
                                                Clear
                                            </v-btn>
                                            <v-btn
                                                elevation="0"
                                                class="ml-4 font-weight-600 btn-block text-capitalize btn-ls btn-outline-primary rounded-sm"
                                                color="transparent"
                                                @click="undo(question, index)">
                                                Undo
                                            </v-btn>
                                        </div>
                                    </div>
                                    <div v-if="question.comments.label.length" class="mt-4">
                                        <label for="questionComments" class="label-color font-weight-600 subtitle-2 mb-2 d-block">{{ question.comments.label }}</label>
                                        <v-textarea
                                            :readonly="submissionCopy.status === 'Submitted'"
                                            id="questionComments"
                                            v-model="question.comments.comment"
                                            rows="1"
                                            hide-details
                                            outlined
                                            class="input-style font-size-input text-color-gray mt-0"
                                            placeholder="Additional comments">
                                        </v-textarea>
                                    </div>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="12" md="8" class="my-4 d-flex justify-end">
                            <v-pagination
                                prev-icon="fa fa-angle-left"
                                next-icon="fa fa-angle-right"
                                class="pagination"
                                color="#6F70FF"
                                v-model="currentPage"
                                :length="submissionCopy.sections.length"
                                circle></v-pagination>
                        </v-col>
                    </v-row>
                </v-form>
            </div>
        </div>
        <v-overlay :value="isLoading" absolute>
            <v-progress-circular indeterminate size="32"></v-progress-circular>
        </v-overlay>
        <vue-internet-checker @status="internetStatus" />
    </div>
</template>

<script>
import { makeGetMixin, makeFindMixin } from 'feathers-vuex';
import { mapActions, mapGetters } from 'vuex';
import Uploader from '@/components/UploaderV2/Uploader.vue';
import vueInternetChecker from 'vue-internet-checker';
import isMobile from '../../../plugins/isMobile';
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
    components: {
        Uploader,
        vueInternetChecker,
        QrcodeStream
    },
    mixins: [
        makeGetMixin({
            service: 'submissions',
            name: 'submission',
            id: 'submissionId',
            queryWhen: 'submissionId',
            watch: true
        }),
        makeFindMixin({ service: 'users', watch: true })
    ],
    data() {
        return {
            isMobile: isMobile(),
            submitDialog: false,
            transferDialog: false,
            submissionCopy: {},
            initialSubmission: {},
            saveDialog: false,
            userTransfer: null,
            resolve: null,
            reject: null,
            currentPage: 1,
            isUploading: false,
            hasUploadingFailed: false,
            qrIcon: require('@/assets/img/illustrations/qrcode-solid.svg'),
            date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString(),
            onLine: true,
            isOffline: false,
            isLoading: false,
            qrCodeDialog: false,
            pageChanged: false,
            camera: 'auto',
            selected: { text: 'outline', value: this.paintOutline },
            rules: {
                required: (value) => (value && Boolean(value)) || '*This field is required'
            },
            isGeneratingPDF: false
        };
    },
    methods: {
        async downloadFile(answer) {
            const image = await fetch(`${this.$feathersConnectionString}/${answer.url}`);
            const imageBlob = await image.blob();
            const imageURL = URL.createObjectURL(imageBlob);

            const anchor = document.createElement('a');
            const fileName = /[^/\\\\]*$/.exec(answer.url)[0];
            anchor.href = imageURL;
            anchor.download = fileName || Date.now().toString();

            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);

            URL.revokeObjectURL(imageURL);
        },
        async saveSubmissionOnImageRemoval() {
            try {
                await this.submissionCopy.save();
            } catch (error) {
                this.showError(error);
            }
        },
        async bulkPDF() {
            this.isGeneratingPDF = true;
            if (this.submissionCopy.status !== 'Submitted') {
                try {
                    await this.submissionCopy.save();
                } catch (error) {
                    this.showError(error);
                    this.isGeneratingPDF = false;
                    return;
                }
            }
            try {
                let formId = this.submissionCopy.form_id;
                let result = await this.$store.dispatch('reporting-qr-bulk/create', {
                    submission_id: this.submissionCopy.id,
                    section_index: this.currentPage - 1
                });
                this.isGeneratingPDF = false;
                if (result?.url) {
                    window.open(`${this.$feathersConnectionString}${result.url}`, '_blank').focus();
                }
            } catch (error) {
                this.showError(error);
                this.isGeneratingPDF = false;
            }
        },
        async pdfGeneration(type, questionId) {
            this.isGeneratingPDF = true;
            if (this.submissionCopy.status !== 'Submitted') {
                try {
                    await this.submissionCopy.save();
                } catch (error) {
                    this.showError(error);
                    this.isGeneratingPDF = false;
                    return;
                }
            }

            let result = null;
            let formId = this.submissionCopy.form_id;

            try {
                switch (type) {
                    case 'empty-form':
                        result = await this.$store.dispatch('reporting-empty/create', {
                            form_id: formId
                        });
                        break;
                    case 'form-results':
                        result = await this.$store.dispatch('reporting-results/create', {
                            submission_id: this.submissionId
                        });
                        break;
                    case 'form_access_qr':
                        result = await this.$store.dispatch('reporting-qr-single/create', {
                            qr_type: 'form_access_qr',
                            submission_id: this.submissionId
                        });
                        break;
                    case 'label_and_qr':
                        result = await this.$store.dispatch('reporting-qr-single/create', {
                            qr_type: 'answer_qr',
                            submission_id: this.submissionId,
                            question_id: questionId
                        });
                        break;
                    case 'label_not_qr':
                        result = await this.$store.dispatch('reporting-qr-single/create', {
                            qr_type: 'answer_qr',
                            submission_id: this.submissionId,
                            question_id: questionId
                        });
                        break;
                    case 'qr_not_label':
                        result = await this.$store.dispatch('reporting-qr-single/create', {
                            qr_type: 'answer_qr',
                            submission_id: this.submissionId,
                            question_id: questionId
                        });
                        break;
                }
                this.isGeneratingPDF = false;
                if (result?.url) {
                    window.open(`${this.$feathersConnectionString}${result.url}`, '_blank').focus();
                }
            } catch (error) {
                this.showError(error);
                this.isGeneratingPDF = false;
            }
        },
        answerQuestion(event, question, index) {
            question.answeredBy = this.authUser.id;

            if (question.type === 'Formula') {
                // eslint-disable-next-line no-prototype-builtins
                if (!question.hasOwnProperty('answer')) {
                    question.answer = Array(question.options.length).fill('');
                }

                question.answer[index] = event;

                return;
            }

            question.answer = event;
        },
        async saveForm() {
            try {
                if (!this.onLine) {
                    this.showError('Network is offline. Progress will be saved once reconnected.');
                    return;
                }

                if (this.submissionCopy.status !== 'Submitted') {
                    this.submissionCopy.status = 'In Progress';
                }

                await this.submissionCopy.save();
                this.initialSubmission = JSON.parse(JSON.stringify(this.submissionCopy));
                this.showSuccess('Successfully saved form progress!');
            } catch (error) {
                this.showError(error);
            }
        },
        async submitForm() {
            this.isLoading = true;

            if (this.$refs.form.validate()) {
                try {
                    this.submissionCopy.status = 'Submitted';
                    await this.submissionCopy.save();
                    this.submitDialog = false;
                    this.initialSubmission = JSON.parse(JSON.stringify(this.submissionCopy));
                    this.isLoading = false;
                    this.$router.push({ path: '/my-forms?tab=completed-forms' });
                    this.showSuccess('Successfully submitted form!');
                } catch (error) {
                    this.isLoading = false;
                    this.showError(error);
                }
            } else {
                this.showError('Some of the required fields have not been filled in.');
                this.submitDialog = false;
                this.isLoading = false;
            }
        },
        async transferForm() {
            try {
                this.submissionCopy.status = 'Transferred';
                this.submissionCopy.assigned = this.userTransfer.id;
                this.submissionCopy.transferred_by = this.authUser.id;
                this.submissionCopy.transferredAt = new Date().toISOString();
                await this.submissionCopy.save();
                this.transferDialog = false;
                this.initialSubmission = JSON.parse(JSON.stringify(this.submissionCopy));
                this.$router.push({ path: '/my-forms' });
                this.showSuccess('Successfully transferred form!');
            } catch (error) {
                this.showError(error);
            }
        },
        async verifyForm() {
            try {
                this.submissionCopy.verified = true;
                this.submissionCopy.verified_by = this.authUser.id;
                this.submissionCopy.verifiedAt = new Date().toISOString();
                await this.submissionCopy.save();
                this.initialSubmission = JSON.parse(JSON.stringify(this.submissionCopy));
                this.showSuccess('Successfully verified form!');
            } catch (error) {
                this.showError(error);
            }
        },
        formulaSum(answers) {
            let sum = 0;

            if (!answers) return;

            let filteredAnswers = answers.filter((el) => el !== '');

            for (let i = 0; i < filteredAnswers.length; i++) {
                if (!filteredAnswers[i].length) continue;

                sum += parseFloat(filteredAnswers[i]);
            }

            return sum;
        },
        formulaAverage(answers) {
            if (!answers) return;

            let filteredAnswers = answers.filter((el) => el !== '');

            const average = filteredAnswers.reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / filteredAnswers.length;

            return average;
        },
        formulaMin(answers) {
            if (!answers) return;

            var filteredAnswers = answers.filter((el) => {
                return el.length;
            });

            var convertedAnswers = filteredAnswers.map(Number);

            return Math.min.apply(Math, convertedAnswers);
        },
        formulaMax(answers) {
            if (!answers) return;

            var filteredAnswers = answers.filter((el) => {
                return el.length;
            });

            var convertedAnswers = filteredAnswers.map(Number);

            return Math.max.apply(Math, convertedAnswers);
        },
        undo(question, index) {
            this.$refs[`signaturePad_${this.currentPage - 1}_${index}`][0].undoSignature();
            question.answer = '';
        },
        clear(question, index) {
            this.$refs[`signaturePad_${this.currentPage - 1}_${index}`][0].clearSignature();
            question.answer = '';
        },
        save(question, index) {
            const { isEmpty, data } = this.$refs[`signaturePad_${this.currentPage - 1}_${index}`][0].saveSignature('image/png', 0.5);

            if (isEmpty) {
                this.showError('Signature not filled in.');
            } else {
                question.answer = data;
                this.showSuccess('Signature saved.');
            }
        },
        onEnd(index, question) {
            const { data } = this.$refs[`signaturePad_${this.currentPage - 1}_${index}`][0].saveSignature('image/png', 0.5);
            question.answer = data;
        },
        onDecode(result, question) {
            console.log(question.name);
            question.answer = result;
            console.log(question.answer);

            this.pause();
            this.timeout(500);
            this.unpause();

            this.qrCodeDialog = false;
        },
        async onInit(promise) {
            try {
                await promise;
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    this.error = 'ERROR: you need to grant camera access permission';
                } else if (error.name === 'NotFoundError') {
                    this.error = 'ERROR: no camera on this device';
                } else if (error.name === 'NotSupportedError') {
                    this.error = 'ERROR: secure context required (HTTPS, localhost)';
                } else if (error.name === 'NotReadableError') {
                    this.error = 'ERROR: is the camera already in use?';
                } else if (error.name === 'OverconstrainedError') {
                    this.error = 'ERROR: installed cameras are not suitable';
                } else if (error.name === 'StreamApiNotSupportedError') {
                    this.error = 'ERROR: Stream API is not supported in this browser';
                } else if (error.name === 'InsecureContextError') {
                    this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
                } else {
                    this.error = `ERROR: Camera error (${error.name})`;
                }
            }
        },
        unpause() {
            this.camera = 'auto';
        },
        pause() {
            this.camera = 'off';
        },
        timeout(ms) {
            return new Promise((resolve) => {
                window.setTimeout(resolve, ms);
            });
        },
        paintOutline(detectedCodes, ctx) {
            for (const detectedCode of detectedCodes) {
                const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

                ctx.strokeStyle = 'red';

                ctx.beginPath();
                ctx.moveTo(firstPoint.x, firstPoint.y);
                for (const { x, y } of otherPoints) {
                    ctx.lineTo(x, y);
                }
                ctx.lineTo(firstPoint.x, firstPoint.y);
                ctx.closePath();
                ctx.stroke();
            }
        },
        navigateBack() {
            this.$router.go(-1);
        },
        assignUser(user) {
            this.userTransfer = user;
        },
        userInitials(user) {
            const initials = (user.first_name + ' ' + user.last_name).match(/\b\w/g) || [];

            return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        },
        retrieveUser(userId) {
            const foundUser = this.users.find((user) => user.id === userId);

            if (foundUser === undefined) {
                return false;
            }

            return foundUser;
        },
        openSaveDialog() {
            this.saveDialog = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        confirmSave() {
            this.resolve(true);
            this.saveDialog = false;
        },
        cancelSave() {
            this.resolve(false);
            this.saveDialog = false;
        },
        prev() {
            this.currentPage--;
        },
        next() {
            this.currentPage++;
        },
        internetStatus(val) {
            this.onLine = val;
            this.isOffline = !val;

            if (val) {
                this.saveForm();
            }
        },
        loadAllSignatures() {},
        ...mapActions('app', ['showSuccess', 'showError'])
    },
    computed: {
        usersParams() {
            if (!this.authUser) return;

            return {
                query: {
                    $sort: true,
                    id: {
                        $ne: this.authUser.id
                    },
                    role: {
                        $ne: 'Admin'
                    }
                }
            };
        },
        submissionId() {
            return this.$route.params && this.$route.params.id;
        },
        sectionsLength() {
            if (this.submissionCopy.sections && this.submissionCopy.sections.length) {
                return this.submissionCopy.sections.length;
            } else {
                return 1;
            }
        },
        sectionQRCount() {
            let count = 0;
            if (!this.submissionCopy?.sections[this.currentPage - 1]?.questions) return 0;
            this.submissionCopy.sections[this.currentPage - 1].questions.forEach((q) => {
                if (q.qrLabel) count++;
            });
            return count;
        },
        ...mapGetters('apiAuth', { authUser: 'user' })
    },
    async beforeRouteLeave(to, from, next) {
        if (Object.entries(this.initialSubmission).toString() !== Object.entries(this.submissionCopy).toString()) {
            if (await this.openSaveDialog()) {
                this.saveForm();
                next();
            }
        }

        next();
    },
    watch: {
        isGetSubmissionPending(pending) {
            if (!pending && this.submission) {
                this.submissionCopy = this.submission;
                this.initialSubmission = JSON.parse(JSON.stringify(this.submissionCopy));

                this.$nextTick(() => {
                    if (this.submissionCopy.status === 'Submitted') return;
                    if (this.submissionCopy && this.submissionCopy.sections) {
                        this.submissionCopy.sections[this.currentPage - 1].questions.forEach((question, index) => {
                            if (question.type === 'Signature') {
                                this.$refs[`signaturePad_${this.currentPage - 1}_${index}`][0].fromDataURL(question.answer);
                            }
                        });
                    }
                });
            }
        },
        currentPage: {
            handler: function (newVal, oldVal) {
                if (!newVal) return;

                if (newVal !== oldVal) {
                    this.$nextTick(() => {
                        if (this.submissionCopy.status === 'Submitted') return;
                        this.submissionCopy.sections[newVal - 1].questions.forEach((question, index) => {
                            if (question.type === 'Signature') {
                                this.$refs[`signaturePad_${newVal - 1}_${index}`][0].fromDataURL(question.answer);
                            }
                        });
                    });
                }
            },
            immediate: false
        }
    }
};
</script>
<style lang="scss" scoped>
.form-editor-page-wrapper {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;

    .form-editor-container {
        height: 100%;

        .form-editor-sidebar {
            flex-grow: 0;
            min-width: 90px;
            max-width: 90px;
        }
        .form-editor-page {
            flex-grow: 1;
        }
    }
}

.image {
    max-width: 16rem !important;
    // margin: auto;
}

.question-image {
    display: flex;
    justify-content: center;

    img {
        max-width: 20rem !important;
        border-radius: 1.5rem;
    }
}

.title-section {
    position: relative;

    // .download-btn {
    //     position: absolute;
    //     right: 0;
    //     top: -0.5rem;
    // }
}

.error-msg {
    color: #f5365c;
    font-size: 12px;
}

@media screen and (max-width: 640px) {
    .xs-wrap {
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .xs-section-wrap {
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
    }

    .xs-display-none {
        display: none;
    }

    .xs-display {
        display: inline;
    }

    .xs-margin-top {
        margin-top: 0.5rem;
    }
}

@media screen and (min-width: 641px) {
    .xs-display {
        display: none;
    }
}

.signature {
    border: double 2px transparent;
    border-radius: 10px;
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, #000000, #000000);
    background-origin: border-box;
    background-clip: content-box, border-box;
    margin: 0 auto;
}

.container {
    width: 100%;
    padding: 8px 16px;
}

.buttons {
    display: flex;
    gap: 8px;
    justify-content: center;
    margin-top: 8px;
}

.qrcode-streamer {
    width: 320px;
    height: 240px;
    margin: 0 auto;
    margin-top: 2rem;
}

.qrcode-streamer-mobile {
    width: 240px;
    height: 160px;
    margin: 0 auto;
    margin-top: 2rem;
}
</style>
