<template>
    <v-row class="d-flex justify-center">
        <v-col cols="12" sm="8" md="8" lg="6" xl="4">
            <v-card class="xs-margin xs-padding sm-padding card-shadow bg-white">
                <v-form>
                    <h1 class="text-h1 font-weight-600 text-black mb-2 pt-5">You’re all set up!</h1>
                    <p class="text-black font-size-root font-weight-thin mb-8">
                        You’ve succesfully set up your GoDigy account for <span class="subtitle-1">{{ orgName }}</span
                        >. An email has been sent to <span class="subtitle-1">{{ email }}</span> to verify your account.
                    </p>
                    <div class="illustration-img">
                        <img :src="illustration" :alt="illustration" />
                    </div>
                    <v-btn @click="resend" elevation="0" block  height="43" class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm mt-6" color="#6F70FF">Resend Verification</v-btn>
                </v-form>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import { mapActions } from "vuex";
import { authManagement } from "@/store/api";

export default {
    name: "setup-complete",
    data() {
        return {
            checkbox: false,
            showPassword: false,
            illustration: require("@/assets/img/illustrations/undraw_Done_re_oak4.svg")
        };
    },
    methods: {
        async resend() {
            try {
                await authManagement.create({
                    action: "resendVerifySignup",
                    value: { email: this.email }
                });
            } catch (error) {
                this.showError(error);
            }
        },
        ...mapActions("app", ["showSuccess", "showError"])
    },
    computed: {
        email() {
            return (this.$route.params && this.$route.params.email) || "";
        },
        orgName() {
            return (this.$route.params && this.$route.params.orgName) || "";
        }
    }
};
</script>

<style lang="scss" scoped>
.illustration-img {
    display: flex;
    justify-content: center;

    img {
        height: 15rem;
    }
}

@media screen and (max-width: 599px) {
    .xs-margin {
        margin: 0 10px;
    }

    .xs-padding {
        padding: 10px;
    }
}

@media screen and (min-width: 600px) {
    .sm-padding {
        padding: 40px;
    }
}
</style>
