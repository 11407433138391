<template>
    <div v-if="!filteredForms.length && !searchQuery.length" class="text-center pa-3 mb-2">
        <h1 class="text-h1 font-weight-600 text-black text-center mb-2 pt-5">
            No Forms assigned!
        </h1>
        <p class="text-black font-size-root text-center font-weight-thin mb-2">
            When someone assigns you to a form or transfers a form to you, it will appear here.
        </p>
        <v-img class="my-10" :src="coverIllustration" max-height="300" contain />
    </div>
    <div v-else-if="!filteredForms.length && searchQuery.length" class="text-center pa-3 mb-2">
        <h1 class="text-h1 font-weight-600 text-black text-center mb-2 pt-5">
            Not Found
        </h1>
        <p class="text-black font-size-root text-center font-weight-thin mb-2">
            No results found for: {{ searchQuery }}
        </p>
        <v-img class="my-10" :src="notFoundIllustration" max-height="300" contain />
    </div>
    <v-row v-else-if="filteredForms.length">
        <v-col v-for="(form, index) in filteredForms" :key="index" cols="12" sm="12" md="6" lg="4" xl="3">
            <v-card class="card-shadow-2 pa-1 ma-2" solo>
                <v-hover>
                    <template v-slot:default="{ hover }">
                        <v-card class="ma-2 mb-0 py-2" color="#F3F4FD">
                            <v-img class="sm-max-width md-max-width" :src="formIllustration" max-height="200" contain />
                            <v-fade-transition>
                                <v-overlay
                                    v-if="hover"
                                    absolute
                                    opacity="0.1"
                                >
                                    <v-btn @click="saveInitialSubmission(form)" elevation="0"  height="40" outlined class="font-weight-600 text-capitalize btn-primary rounded-xs mr-4" color="#6F70FF"> 
                                        <span class="text-primary">Fill in form</span>
                                    </v-btn>
                                </v-overlay>
                            </v-fade-transition>
                        </v-card>
                    </template>
                </v-hover>
                
                <v-row class="px-4">
                    <v-col cols="12" class="pb-0 pl-0">
                        <v-card-title class="font-weight-600 subtitle-2 d-block pl-2 pb-1">{{ form.name }}</v-card-title>
                    </v-col>
                    <v-col cols="12" class="ma-0 py-2 pb-1 pl-1">
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" class="pt-0 d-flex justify-space-between pl-1">
                        <div class="font-weight-400 label text-light d-flex align-center" >
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <feather type="file-text" class="text-darker mr-1" size="1.2rem" v-bind="attrs" v-on="on" stroke-width="1.2"></feather>
                                    {{ lastEdited(form.updatedAt) }}
                                </template>
                                <span>Assigned</span>
                            </v-tooltip>
                        </div>
                        <v-avatar size="33" color="#F3F4FD" >
                            <span class="text-primary text-h5 font-weight-600">Me</span>
                        </v-avatar>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col v-if="totalPagesLength > 1" cols="12" class="mt-4 d-flex justify-end">
            <v-pagination
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                class="pagination"
                color="#6F70FF"
                v-model="currentPage"
                :length="totalPagesLength"
                circle
            ></v-pagination>
        </v-col>
    </v-row>
    
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import { mapActions } from "vuex";
import { makeFindMixin, models } from 'feathers-vuex'
import { mapGetters } from "vuex";

export default {
    components: {
    },
    props: {
        searchQuery: {
            type: String,
            required: true
        }, 
        filterOption: {
            type: String,
            required: true
        },
        dateRange: {
            type: Array,
            required: true
        }
    },
    mixins: [
        makeFindMixin({ service: 'forms', watch: true })
    ],
    data() {
        return {
            formIllustration: require("@/assets/img/illustrations/undraw_attached_file_re_0n9b.svg"),
            coverIllustration: require("@/assets/img/illustrations/undraw_Forms_re_pkrt.svg"),
            notFoundIllustration: require("@/assets/img/illustrations/undraw_no_data_re_kwbl.svg"),
            deleteDialog: false,
            currentPage: 1,
            perPage: 16,
            totalPages: 1
        };
    },
    methods: {
        async deleteForm(form) {
            try {
                await form.remove()
                this.deleteDialog = false;
                this.showSuccess("Form successfully deleted!");
            } catch (error) {
                this.showError(error);
            }
        },
        async saveInitialSubmission(form) {
            try {
                const { Submission } = models.api;
                const submission = new Submission();

                submission.manager_id = form.manager_id
                submission.form_id = form.id
                submission.assigned = this.authUser.id
                submission.name = form.name
                submission.status = form.status
                submission.schedule = form.schedule
                submission.sections = form.sections
                submission.status = 'In Progress'

                const res = await submission.save();
                this.showSuccess("A new submission has been created.");
                this.$router.push( { path: `/edit-submission/${res.id}`} )
            } catch (error) {
                this.showError(error);
            }
        },
        lastEdited(time) {
            var d = new Date(time);
            return this.$moment(d).calendar()
        },
        ...mapActions("app", ["showSuccess", "showError"])
    },
    computed: {
        filteredForms() {
            return this.forms.filter((form) => {
                const formObj = JSON.stringify(form);
                return formObj.includes(this.searchQuery);
            })
        },
        formsParams() {
            if (!this.authUser) return;
            
			const query = {
                hide: false,
				status: 'Published',
                disabled: false,
                $limit: this.perPage,
                $skip: (this.currentPage - 1) * this.perPage,
                $sort: {
                    updatedAt: -1
                }
			}

            let lessThanDate = ''
            let greaterThanDate = ''

            if (this.dateRange.length === 1) {
                lessThanDate = this.$moment(this.dateRange[0]).add(1, 'days')
                greaterThanDate = this.$moment(this.dateRange[0])

                query.createdAt = {
                    $gte: greaterThanDate,
                    $lte: lessThanDate
                }
            } else if (this.dateRange.length === 2) {
                lessThanDate = this.$moment(this.dateRange[1]).add(1, 'days')
                greaterThanDate = this.$moment(this.dateRange[0])

                query.createdAt = {
                    $gte: greaterThanDate,
                    $lte: lessThanDate
                }
            }

			if (this.filterOption === 'ascending') {
                query.$sort = {
                    name: 1
                };
            } else if (this.filterOption === 'descending') {
                query.$sort = {
                    name: -1
                };
            } else if (this.filterOption === 'latestCreated') {
                query.$sort = {
                    createdAt: -1
                };
            } else if (this.filterOption === 'oldestCreated') {
                query.$sort = {
                    createdAt: 1
                };
            } else if (this.filterOption === 'latestUpdated') {
                query.$sort = {
                    updatedAt: 1
                };
            } else if (this.filterOption === 'oldestUpdated') {
                query.$sort = {
                    updatedAt: 1
                };
            }

            return {
                query: query
            }
        },
        totalPagesLength() {
            const formsPaginationData = this.formsPaginationData.default;
            console.log(this.formsPaginationData)
            if (formsPaginationData && formsPaginationData.mostRecent.total) {
                this.totalPages = Math.ceil(formsPaginationData.mostRecent.total / this.perPage);

                if (formsPaginationData.mostRecent.total / this.totalPages === this.perPage) {
                    this.currentPage = 1;
                }
            }

            return this.totalPages
        },
        ...mapGetters("apiAuth", { authUser: "user" })
    }
};
</script>
<style lang="scss" scoped>
.card-shadow-2 {
	box-shadow: 0 0 1rem 0 rgb(136 152 170 / 15%) !important;
}

.label {
    font-size: 0.8rem;
}

@media screen and (max-width: 600px) {
    .sm-max-width {
        max-width: 150px;
    }
}

@media screen and (min-width: 601px) {
    .md-max-width {
        max-width: 326px;
    }
}

/* .cover-img {
    width: 100%;
    max-height: 200px; 
    object-fit: contain;
} */
</style>



