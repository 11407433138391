<template>
    <div v-if="!filteredForms.length && !searchQuery.length" class="text-center pa-3 mb-2">
        <h1 class="text-h1 font-weight-600 text-black text-center mb-2 pt-5">No Forms are hidden!</h1>
        <p class="text-black font-size-root text-center font-weight-thin mb-2">All the hidden forms will display here.</p>
        <v-img class="my-10" :src="coverIllustration" max-height="300" contain />
    </div>
    <div v-else-if="!filteredForms.length && searchQuery.length" class="text-center pa-3 mb-2">
        <h1 class="text-h1 font-weight-600 text-black text-center mb-2 pt-5">Not Found</h1>
        <p class="text-black font-size-root text-center font-weight-thin mb-2">No results found for: {{ searchQuery }}</p>
        <v-img class="my-10" :src="notFoundIllustration" max-height="300" contain />
    </div>
    <v-row v-else-if="filteredForms.length">
        <v-col v-for="(form, index) in filteredForms" :key="index" sm="6" md="6" lg="4" xl="3">
            <v-card class="card-shadow-2 pa-1 ma-2" solo>
                <v-hover>
                    <template v-slot:default="{ hover }">
                        <v-card class="ma-2 mb-0 py-2" color="#F3F4FD">
                            <v-img v-if="form.status === 'Published'" :src="formIllustration" max-height="200" max-width="326" contain />
                            <v-img v-if="form.status === 'Draft'" :src="formIllustration2" max-height="200" max-width="326" contain />
                            <v-fade-transition>
                                <v-overlay v-if="hover" absolute opacity="0.1">
                                    <router-link v-if="form.status != 'Published'" :to="`/edit-form/${form.id}`" class="text-decoration-none btn-hover no-default-hover">
                                        <v-btn elevation="0" height="40" outlined class="font-weight-600 text-capitalize btn-primary rounded-xs mr-4" color="#6F70FF">
                                            <span class="text-primary">View Form</span>
                                        </v-btn>
                                    </router-link>
                                    <router-link v-else :to="`/preview-form/${form.id}`" class="text-decoration-none btn-hover no-default-hover">
                                        <v-btn elevation="0" height="40" outlined class="font-weight-600 text-capitalize btn-primary rounded-xs mr-4" color="#6F70FF">
                                            <span class="text-primary">View Preview</span>
                                        </v-btn>
                                    </router-link>
                                </v-overlay>
                            </v-fade-transition>
                        </v-card>
                    </template>
                </v-hover>
                <div class="d-flex justify-space-between align-center">
                    <div>
                        <v-card-title class="font-weight-600 subtitle-2 d-block pl-2">{{ form.name }}</v-card-title>
                        <v-card-subtitle class="font-weight-400 subtitle-2 d-block pl-2">{{ form.status }}</v-card-subtitle>
                    </div>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="hideForm(form)" class="mb-5" icon v-bind="attrs" v-on="on">
                                <v-avatar size="33" color="#F3F4FD">
                                    <feather type="eye" class="text-darker" size="1.2rem" stroke-width="1.2"></feather>
                                </v-avatar>
                            </v-btn>
                        </template>
                        <span>Show Form</span>
                    </v-tooltip>
                </div>

                <v-row class="px-4">
                    <v-col cols="12" class="pb-0 pl-1 d-flex justify-space-between">
                        <div class="font-weight-400 label text-light d-flex align-center">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <feather type="calendar" class="text-darker mr-1" size="1.2rem" v-bind="attrs" v-on="on" stroke-width="1.2"></feather>
                                    <span v-if="form.schedule" class="text-light">{{ nextAvailableTime(form.schedule) }}</span>
                                    <span v-else class="text-light">No Schedule</span>
                                </template>
                                <span>Schedule</span>
                            </v-tooltip>
                        </div>
                        <div v-if="form.schedule && form.schedule.type !== 'None'" class="font-weight-400 label text-light d-flex align-center">
                            <span class="text-light">{{ form.schedule.type }}</span>
                        </div>
                    </v-col>
                    <v-col cols="12" class="ma-0 py-2 pb-1 pl-1" v-if="(authUser.role === 'GlobalManager' || authUser.role === 'Admin') && form.manager_id !== authUser.id">
                        <div class="font-weight-400 label text-light d-flex align-center">
                            <feather type="user" class="text-darker mr-1" size="1.2rem" stroke-width="1.2"></feather>
                            <span>
                                Created by&nbsp;<span class="font-weight-600">{{ retrieveUserWhoCreatedForm(form.manager_id) }}</span>
                            </span>
                        </div>
                    </v-col>
                    <v-col cols="12" class="ma-0 py-2 pb-1 pl-1" v-if="(authUser.role === 'GlobalManager' || authUser.role === 'Admin') && form.manager_id === authUser.id">
                        <div class="font-weight-400 label text-light d-flex align-center">
                            <feather type="user" class="text-darker mr-1" size="1.2rem" stroke-width="1.2"></feather>
                            <span> Created by you </span>
                        </div>
                    </v-col>
                    <v-col cols="12" class="ma-0 py-2 pb-1 pl-1">
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" class="pt-0 d-flex justify-space-between pl-1">
                        <div class="font-weight-400 label text-light d-flex align-center">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <feather type="edit" class="text-darker mr-1" size="1.2rem" v-bind="attrs" v-on="on" stroke-width="1.2"></feather>
                                    {{ lastEdited(form.updatedAt) }}
                                </template>
                                <span>Last Updated</span>
                            </v-tooltip>
                        </div>

                        <assign-user-menu :form="form" :disabled="true"></assign-user-menu>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col v-if="totalPagesLength > 1" cols="12" class="mt-4 d-flex justify-end">
            <v-pagination prev-icon="fa fa-angle-left" next-icon="fa fa-angle-right" class="pagination" color="#6F70FF" v-model="currentPage" :length="totalPagesLength" circle></v-pagination>
        </v-col>
        <!-- <pre>{{ filteredForms }}</pre> -->
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AssignUserMenu from '../FormEditor/components/Menus/AssignUserMenu.vue';
import { makeFindMixin, models } from 'feathers-vuex';

export default {
    components: {
        AssignUserMenu
    },
    props: {
        searchQuery: {
            type: String,
            required: true
        },
        filterOption: {
            type: String,
            required: true
        },
        dateRange: {
            type: Array,
            required: true
        }
    },
    mixins: [makeFindMixin({ service: 'forms', watch: true }), makeFindMixin({ service: 'users', watch: true })],
    data() {
        return {
            formIllustration: require('@/assets/img/illustrations/undraw_Collaboration_re_vyau.svg'),
            formIllustration2: require('@/assets/img/illustrations/undraw_Live_collaboration_re_60ha.svg'),
            coverIllustration: require('@/assets/img/illustrations/undraw_Add_files_re_v09g.svg'),
            notFoundIllustration: require('@/assets/img/illustrations/undraw_no_data_re_kwbl.svg'),
            deleteDialog: false,
            currentPage: 1,
            perPage: 16,
            totalPages: 1
        };
    },
    methods: {
        retrieveUserWhoCreatedForm(id) {
            let user = this.retrieveUser(id);
            if (!user) return false;
            return `${user.first_name} ${user.last_name}`;
        },
        retrieveUser(id) {
            const foundUser = this.users.find((user) => user.id === id);

            if (foundUser === undefined) {
                return false;
            }

            return foundUser;
        },
        async deleteForm(form) {
            try {
                await form.remove();
                this.deleteDialog = false;
                this.showSuccess('Form successfully deleted!');
            } catch (error) {
                this.showError(error);
            }
        },
        async hideForm(form) {
            try {
                form.hide = !form.hide;
                await form.save();

                if (form.hide) {
                    this.showSuccess('Form will now be hidden from users.');
                } else {
                    this.showSuccess('Form will now be available for users to fill in.');
                }
            } catch (error) {
                this.showError(error);
            }
        },
        nextAvailableTime(schedule) {
            let today = this.$moment();
            let releaseMoment = this.$moment(schedule.releaseDate);
            let releaseDay = releaseMoment.get('D');
            let releaseIsoDay = releaseMoment.isoWeekday();
            let releaseHours = releaseMoment.get('h');
            let releaseMins = releaseMoment.get('m');

            if (schedule.type === 'None') {
                return 'No schedule';
            } else if (schedule.type === 'Once-off') {
                var rd = new Date(schedule.releaseDate);
                return this.$moment(rd).calendar();
            } else if (schedule.type === 'Monthly') {
                let monthlyRecurringDate = this.$moment().set('D', releaseDay).set('h', releaseHours).set('m', releaseMins).set('s', 0);

                if (today.isAfter(monthlyRecurringDate)) {
                    monthlyRecurringDate = monthlyRecurringDate.add(1, 'month');
                }

                var m = new Date(monthlyRecurringDate.format('YYYY-MM-DD HH:mm'));
                return this.$moment(m).calendar();
            } else if (schedule.type === 'Weekly') {
                let weeklyRecurringDate = this.$moment().isoWeekday(releaseIsoDay).set('h', releaseHours).set('m', releaseMins).set('s', 0);

                if (today.isAfter(weeklyRecurringDate)) {
                    weeklyRecurringDate = weeklyRecurringDate.add(1, 'week');
                }

                var w = new Date(weeklyRecurringDate.format('YYYY-MM-DD HH:mm'));
                return this.$moment(w).calendar();
            } else if (schedule.type === 'Daily') {
                let dailyRecurringDate = this.$moment().set('h', releaseHours).set('m', releaseMins).set('s', 0);

                if (today.isAfter(dailyRecurringDate)) {
                    dailyRecurringDate = dailyRecurringDate.add(1, 'day');
                }

                var d = new Date(dailyRecurringDate.format('YYYY-MM-DD HH:mm'));
                return this.$moment(d).calendar();
            }

            return schedule.type;
        },
        lastEdited(time) {
            var d = new Date(time);
            return this.$moment(d).calendar();
        },
        ...mapActions('app', ['showSuccess', 'showError'])
    },
    computed: {
        usersParams() {
            return {
                query: {
                    $sort: true
                }
            };
        },
        filteredForms() {
            return this.forms.filter((form) => {
                const formObj = JSON.stringify(form);
                return formObj.includes(this.searchQuery);
            });
        },
        formsParams() {
            if (!this.authUser) return;

            let query = {};

            if (this.authUser.role === 'GlobalManager' || this.authUser.role === 'Admin') {
                query = {
                    $limit: this.perPage,
                    $skip: (this.currentPage - 1) * this.perPage,
                    hide: true,
                    $sort: {
                        updatedAt: -1
                    }
                };
            } else {
                query = {
                    $limit: this.perPage,
                    $skip: (this.currentPage - 1) * this.perPage,
                    manager_id: this.authUser.id,
                    hide: true,
                    $sort: {
                        updatedAt: -1
                    }
                };
            }

            let lessThanDate = '';
            let greaterThanDate = '';

            if (this.dateRange.length === 1) {
                lessThanDate = this.$moment(this.dateRange[0]).add(1, 'days');
                greaterThanDate = this.$moment(this.dateRange[0]);

                query.createdAt = {
                    $gte: greaterThanDate,
                    $lte: lessThanDate
                };
            } else if (this.dateRange.length === 2) {
                lessThanDate = this.$moment(this.dateRange[1]).add(1, 'days');
                greaterThanDate = this.$moment(this.dateRange[0]);

                query.createdAt = {
                    $gte: greaterThanDate,
                    $lte: lessThanDate
                };
            }

            if (this.filterOption === 'ascending') {
                query.$sort = {
                    name: 1
                };
            } else if (this.filterOption === 'descending') {
                query.$sort = {
                    name: -1
                };
            } else if (this.filterOption === 'latestCreated') {
                query.$sort = {
                    createdAt: -1
                };
            } else if (this.filterOption === 'oldestCreated') {
                query.$sort = {
                    createdAt: 1
                };
            } else if (this.filterOption === 'latestUpdated') {
                query.$sort = {
                    updatedAt: 1
                };
            } else if (this.filterOption === 'oldestUpdated') {
                query.$sort = {
                    updatedAt: 1
                };
            }

            return {
                query: query
            };
        },
        totalPagesLength() {
            const formsPaginationData = this.formsPaginationData.default;
            if (formsPaginationData && formsPaginationData.mostRecent.total) {
                this.totalPages = Math.ceil(formsPaginationData.mostRecent.total / this.perPage);

                if (formsPaginationData.mostRecent.total / this.totalPages === this.perPage) {
                    this.currentPage = 1;
                }
            }

            return this.totalPages;
        },
        ...mapGetters('apiAuth', { authUser: 'user' })
    }
};
</script>
<style lang="scss" scoped>
.card-shadow-2 {
    box-shadow: 0 0 1rem 0 rgb(136 152 170 / 15%) !important;
}

.label {
    font-size: 0.8rem;
}
</style>



