<script>
import { Bar } from 'vue-chartjs/legacy';

export default {
    extends: Bar,
    props: {
        labels: {
            type: Array,
            default: () => {}
        },
        chartData: {
            type: Array,
            default: () => {}
        },
        chartId: {
            type: String
        }
    },
    data() {
        return {
            data: {
                labels: this.labels,
                datasets: [
                    {
                        borderWidth: 1,
                        backgroundColor: ['#6F70FF', '#D8D9FE', '#F3F3F3', '#000000', '#EAEAEA', '#B2C0FF'],
                        barPercentage: 0.5,
                        pointBorderColor: '#2554FF',
                        data: this.chartData
                    }
                ]
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    }
                },
                scales: {
                    y: [
                        {
                            type: 'linear',
                            ticks: {
                                beginAtZero: true
                            },
                            gridLines: {
                                display: true
                            }
                        }
                    ],
                    x: [
                        {
                            type: 'linear',
                            gridLines: {
                                display: false
                            }
                        }
                    ]
                },
                legend: {
                    display: false
                },
                responsive: true,
                maintainAspectRatio: false
            }
        };
    },
    mounted() {
        this.renderChart(this.data, this.options, this.chartId);
    }
};
</script>