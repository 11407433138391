<template>
    <div class="form-editor-page-wrapper">
        <v-card class="card-shadow pa-4">
            <div class="d-md-flex justify-space-between mr-3">
                <div class="d-flex flex-row align-center flex-grow-1">
                    <v-btn @click="navigateBack()" icon elevation="0" class="rounded-xs mr-2" color="#6F70FF">
                        <feather type="chevron-left" class="text-darker" stroke-width="1.5"></feather>
                    </v-btn>
                    <v-text-field
                        class="text-h2 font-weight-600"
                        :value="`${submissionName} (${submissions.length} submissions)`"
                        solo
                        readonly
                        flat
                        placeholder="Form Name"
                        hide-details="auto"
                        dense
                        auto-grow></v-text-field>
                </div>
                <div class="d-flex align-center">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="displayText = !displayText" class="ml-4" icon v-bind="attrs" v-on="on">
                                <v-avatar size="33" :color="displayText ? '#B7B8FF' : '#F3F4FD'">
                                    <feather type="file-text" class="text-darker" size="20" stroke-width="1.5"></feather>
                                </v-avatar>
                            </v-btn>
                        </template>
                        <span v-if="displayText">Disable Text Questions</span>
                        <span v-else>Enable Text Questions</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="displayDropdown = !displayDropdown" class="ml-4" icon v-bind="attrs" v-on="on">
                                <v-avatar size="33" :color="displayDropdown ? '#B7B8FF' : '#F3F4FD'">
                                    <feather type="list" class="text-darker" size="20" stroke-width="1.5"></feather>
                                </v-avatar>
                            </v-btn>
                        </template>
                        <span v-if="displayDropdown">Disable Dropdown Questions</span>
                        <span v-else>Enable Dropdown Questions</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="displayMultipleChoice = !displayMultipleChoice" class="ml-4" icon v-bind="attrs" v-on="on">
                                <v-avatar size="33" :color="displayMultipleChoice ? '#B7B8FF' : '#F3F4FD'">
                                    <feather type="grid" class="text-darker" size="20" stroke-width="1.5"></feather>
                                </v-avatar>
                            </v-btn>
                        </template>
                        <span v-if="displayMultipleChoice">Disable Single Choice Questions</span>
                        <span v-else>Enable Single Choice Questions</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="displayCheckbox = !displayCheckbox" class="ml-4" icon v-bind="attrs" v-on="on">
                                <v-avatar size="33" :color="displayCheckbox ? '#B7B8FF' : '#F3F4FD'">
                                    <feather type="check-square" class="text-darker" size="20" stroke-width="1.5"></feather>
                                </v-avatar>
                            </v-btn>
                        </template>
                        <span v-if="displayCheckbox">Disable Checkbox Questions</span>
                        <span v-else>Enable Checkbox Questions</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="displayImage = !displayImage" class="ml-4" icon v-bind="attrs" v-on="on">
                                <v-avatar size="33" :color="displayImage ? '#B7B8FF' : '#F3F4FD'">
                                    <feather type="image" class="text-darker" size="20" stroke-width="1.5"></feather>
                                </v-avatar>
                            </v-btn>
                        </template>
                        <span v-if="displayImage">Disable Image Questions</span>
                        <span v-else>Enable Image Questions</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="displayFile = !displayFile" class="ml-4" icon v-bind="attrs" v-on="on">
                                <v-avatar size="33" :color="displayFile ? '#B7B8FF' : '#F3F4FD'">
                                    <feather type="file" class="text-darker" size="20" stroke-width="1.5"></feather>
                                </v-avatar>
                            </v-btn>
                        </template>
                        <span v-if="displayFile">Disable File Questions</span>
                        <span v-else>Enable File Questions</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="displayDate = !displayDate" class="ml-4" icon v-bind="attrs" v-on="on">
                                <v-avatar size="33" :color="displayDate ? '#B7B8FF' : '#F3F4FD'">
                                    <feather type="calendar" class="text-darker" size="20" stroke-width="1.5"></feather>
                                </v-avatar>
                            </v-btn>
                        </template>
                        <span v-if="displayDate">Disable Date Questions</span>
                        <span v-else>Enable Date Questions</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="displayFormula = !displayFormula" class="ml-4" icon v-bind="attrs" v-on="on">
                                <v-avatar size="33" :color="displayFormula ? '#B7B8FF' : '#F3F4FD'">
                                    <feather type="bar-chart-2" class="text-darker" size="20" stroke-width="1.5"></feather>
                                </v-avatar>
                            </v-btn>
                        </template>
                        <span v-if="displayFormula">Disable Formula Questions</span>
                        <span v-else>Enable Formula Questions</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="displaySignature = !displaySignature" class="ml-4" icon v-bind="attrs" v-on="on">
                                <v-avatar size="33" :color="displaySignature ? '#B7B8FF' : '#F3F4FD'">
                                    <feather type="pen-tool" class="text-darker" size="20" stroke-width="1.5"></feather>
                                </v-avatar>
                            </v-btn>
                        </template>
                        <span v-if="displaySignature">Disable Signatures</span>
                        <span v-else>Enable Signatures</span>
                    </v-tooltip>
                    <v-divider vertical class="mx-4"></v-divider>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="generateReport()" icon v-bind="attrs" v-on="on">
                                <v-avatar size="33" color="#F3F4FD">
                                    <feather type="download" class="text-darker" size="20" stroke-width="1.5"></feather>
                                </v-avatar>
                            </v-btn>
                        </template>
                        <span>Download Report</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click.prevent="generateCSV" icon v-bind="attrs" v-on="on" class="ml-4">
                                <v-avatar size="33" color="#F3F4FD">
                                    <feather type="code" class="text-darker" size="20" stroke-width="1.5"></feather>
                                </v-avatar>
                            </v-btn>
                        </template>
                        <span>Download CSV</span>
                    </v-tooltip>
                    <v-divider vertical class="mx-4"></v-divider>
                    <v-menu ref="menu" v-model="dateMenu" :close-on-content-click="false" offset-y bottom left min-width="auto">
                        <template #activator="menu">
                            <v-tooltip bottom>
                                <template #activator="tooltip">
                                    <v-btn icon v-on="{ ...menu.on, ...tooltip.on }">
                                        <v-avatar size="33" :color="dateRange.length ? '#B7B8FF' : '#F3F4FD'">
                                            <feather type="calendar" class="text-darker" size="1.2rem" stroke-width="1.5"></feather>
                                        </v-avatar>
                                    </v-btn>
                                </template>

                                <span>Filter between two dates</span>
                            </v-tooltip>
                        </template>
                        <v-date-picker v-model="dateRange" no-title scrollable range color="#6F70FF">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="clearDateRange()"> Clear </v-btn>
                            <v-btn text color="primary" @click="setDateRange()"> OK </v-btn>
                        </v-date-picker>
                    </v-menu>
                </div>
            </div>
        </v-card>
        <div id="pdfGeneration" v-if="submissions && submissions.length" class="d-flex flex-row mt-6 form-editor-container">
            <div class="form-editor-page">
                <v-row class="d-flex justify-center">
                    <v-col sm="10" md="10" lg="10" xl="8" class="pt-3">
                        <v-card class="card-shadow">
                            <div class="card-title-wrapper pa-6 py-4">
                                <v-card-title class="text-h1 font-weight-600 mb-6 pa-0">{{ submissionName }} - {{ submissionTitle }} </v-card-title>
                                <v-card-subtitle class="text-subtitle-2 text-light subtitle-2 pa-0">{{ submissionDescription }} </v-card-subtitle>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col v-for="(question, index) in submissionQuestions" :key="index" sm="10" md="10" lg="10" xl="8">
                        <v-hover>
                            <template v-slot:default="{ hover }">
                                <v-card class="card-shadow">
                                    <div class="card-title-wrapper pa-6">
                                        <div class="d-flex align-center mb-2 justify-space-between title-section">
                                            <div class="d-flex align-center">
                                                <feather :type="questionIcon(questionGroup(index)[0].type)" class="text-darker" size="20" stroke-width="1.5"></feather>
                                                <label class="label-color font-weight-600 subtitle-2 d-block ml-1">{{ index + 1 }}. {{ question.name }}</label>
                                            </div>
                                            <v-fade-transition
                                                v-if="
                                                    questionGroup(index)[0].type === 'Dropdown' ||
                                                    questionGroup(index)[0].type === 'Single Choice' ||
                                                    questionGroup(index)[0].type === 'Checkbox' ||
                                                    questionGroup(index)[0].type === 'Image' ||
                                                    questionGroup(index)[0].type === 'File'
                                                ">
                                                <v-btn @click="downloadGraph(index)" v-if="hover" class="download-btn" icon>
                                                    <feather type="download" class="text-darker" size="20" stroke-width="1.5"></feather>
                                                </v-btn>
                                            </v-fade-transition>
                                        </div>
                                        <div v-if="questionGroup(index)[0].type === 'Text' && displayText">
                                            <div v-for="(group, index) in questionGroup(index)" :key="index">
                                                <span class="font-weight-600 subtitle-2">Answer {{ index + 1 }}.</span>
                                                <span v-if="group.answer && group.answer.length > 150" class="font-weight-400 subtitle-2 text-cap"> {{ group.answer.substring(0, 150) + '...' }}</span>
                                                <span v-else-if="group.answer && group.answer.length <= 150" class="font-weight-400 subtitle-2 text-cap"> {{ group.answer }}</span>
                                                <span v-if="group.answer" class="font-weight-400 subtitle-2">
                                                    ({{ retrieveUser(group.answeredBy).first_name }} {{ retrieveUser(group.answeredBy).last_name }})</span
                                                >
                                                <span v-else class="font-weight-400 subtitle-2"> N/A</span>
                                            </div>
                                        </div>
                                        <div class="chart-area">
                                            <pie-chart
                                                ref="chart"
                                                :chartId="`chart-${index}`"
                                                v-if="(questionGroup(index)[0].type === 'Single Choice' && displayMultipleChoice) || (questionGroup(index)[0].type === 'Dropdown' && displayDropdown)"
                                                :labels="questionLabels(questionGroup(index))"
                                                :chartData="questionData(questionGroup(index))"></pie-chart>
                                            <bar-chart
                                                ref="chart"
                                                :chartId="`chart-${index}`"
                                                v-if="questionGroup(index)[0].type === 'Checkbox' && displayCheckbox"
                                                :labels="questionLabels(questionGroup(index))"
                                                :chartData="questionData(questionGroup(index))"></bar-chart>
                                            <file-chart
                                                ref="chart"
                                                :chartId="`chart-${index}`"
                                                v-if="questionGroup(index)[0].type === 'Image' && displayImage && imageTotal(questionGroup(index))[0] > 0"
                                                :labels="['Image Upload Total']"
                                                :chartData="imageTotal(questionGroup(index))"></file-chart>
                                            <file-chart
                                                ref="chart"
                                                :chartId="`chart-${index}`"
                                                v-if="questionGroup(index)[0].type === 'File' && displayFile && fileTotal(questionGroup(index))[0] > 0"
                                                :labels="['File Upload Total']"
                                                :chartData="fileTotal(questionGroup(index))"></file-chart>
                                        </div>
                                        <div v-if="questionGroup(index)[0].type === 'Image' && displayFile && imageTotal(questionGroup(index))[0] === 0">
                                            <span> No images have been uploaded</span>
                                        </div>
                                        <div v-if="questionGroup(index)[0].type === 'File' && displayFile && fileTotal(questionGroup(index))[0] === 0">
                                            <span> No files have been uploaded</span>
                                        </div>
                                        <div v-if="questionGroup(index)[0].type === 'Date' && displayDate">
                                            <div v-for="(group, index) in questionGroup(index)" :key="index">
                                                <span class="font-weight-600 subtitle-2">Answer {{ index + 1 }}.</span>
                                                <span v-if="group.answer" class="font-weight-400 subtitle-2"> {{ group.answer }}</span>
                                                <span v-if="group.answer" class="font-weight-400 subtitle-2">
                                                    ({{ retrieveUser(group.answeredBy).first_name }} {{ retrieveUser(group.answeredBy).last_name }})</span
                                                >
                                                <span v-else class="font-weight-400 subtitle-2"> N/A</span>
                                            </div>
                                        </div>
                                        <div v-if="questionGroup(index)[0].type === 'Formula' && displayFormula">
                                            <div v-for="(group, index) in questionGroup(index)" :key="index">
                                                <span v-if="group.answer" class="font-weight-600 subtitle-2">
                                                    Answer {{ index + 1 }}. ({{ retrieveUser(group.answeredBy).first_name }} {{ retrieveUser(group.answeredBy).last_name }})
                                                </span>
                                                <div v-if="group.answer">
                                                    <span v-for="(option, j) in group.options" :key="j" class="font-weight-400 subtitle-2">
                                                        {{ option.value }}:
                                                        <span v-if="group.answer[j]">{{ group.answer[j] }}</span>
                                                        <span v-else>N/A</span>
                                                        <span v-if="j < group.options.length - 1">, </span>
                                                    </span>
                                                </div>
                                                <span v-else class="font-weight-600 subtitle-2"> Answer {{ index + 1 }}. <span class="font-weight-400 subtitle-2">N/A</span> </span>
                                            </div>
                                        </div>
                                        <div v-if="questionGroup(index)[0].type === 'Signature' && displaySignature && signatureTotal(questionGroup(index))[0] > 0">
                                            <span> {{ signatureTotal(questionGroup(index))[0] }} signatures have been uploaded</span>
                                        </div>
                                    </div>
                                </v-card>
                            </template>
                        </v-hover>
                    </v-col>
                    <v-col cols="8" class="my-4 d-flex justify-end">
                        <v-pagination
                            prev-icon="fa fa-angle-left"
                            next-icon="fa fa-angle-right"
                            class="pagination"
                            color="#6F70FF"
                            v-model="currentPage"
                            :length="sectionsLength"
                            circle></v-pagination>
                    </v-col>
                </v-row>
            </div>
        </div>
        <div v-else-if="!submissions.length && dateRange.length" class="text-center pa-3 mb-2">
            <h1 class="text-h1 font-weight-600 text-black text-center mb-2 pt-5">No analytics forms found for date(s):</h1>
            <p class="text-black font-size-root text-center font-weight-thin mb-2">
                <span v-if="dateRange[0]">{{ dateRange[0] }}</span>
                <span v-if="dateRange[1]"> to {{ dateRange[1] }}</span>
            </p>
            <v-img class="my-10" :src="notFoundIllustration" max-height="300" contain />
        </div>
    </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import { mapGetters, mapActions } from 'vuex';
import PieChart from './components/PieChart.vue';
import BarChart from './components/BarChart.vue';
import FileChart from './components/FileChart.vue';

export default {
    components: {
        PieChart,
        BarChart,
        FileChart
    },
    data() {
        return {
            currentPage: 1,
            displayText: false, //false
            displayDropdown: true,
            displayMultipleChoice: true,
            displayCheckbox: true,
            displayImage: false, //false
            displayFile: false, //false
            displayDate: true,
            displayFormula: true,
            displaySignature: false,
            dateMenu: '',
            dateRange: [],
            dates: [],
            notFoundIllustration: require('@/assets/img/illustrations/undraw_no_data_re_kwbl.svg')
        };
    },
    mixins: [makeFindMixin({ service: 'submissions', watch: true }), makeFindMixin({ service: 'users', watch: true })],
    methods: {
        generateReport() {
            window.print();
        },
        downloadGraph(index) {
            const id = `chart-${index}`;

            let canvas = document.getElementById(id).toDataURL('image/png');
            let link = document.createElement('a');
            link.download = 'chart';
            link.href = canvas;
            link.click();
        },
        questionGroup(index) {
            if (this.submissions.length) {
                const questionGroup = [];

                this.submissions.forEach((submission) => {
                    questionGroup.push(submission.sections[this.currentPage - 1].questions[index]);
                });

                return questionGroup;
            }

            return [];
        },
        questionLabels(questionGroup) {
            const questionLabels = [];

            questionGroup.forEach((question) => {
                if (question.type === 'Checkbox') {
                    question.answer.forEach((value) => {
                        if (questionLabels.includes(value)) {
                            return;
                        }

                        questionLabels.push(value);
                    });
                    return;
                }

                if (questionLabels.includes(question.answer)) {
                    return;
                }

                questionLabels.push(question.answer);
            });

            return questionLabels;
        },
        questionData(questionGroup) {
            const questionData = [];

            questionGroup.forEach((question) => {
                if (question.type === 'Checkbox') {
                    question.answer.forEach((value) => {
                        questionData.push(value);
                    });
                    return;
                }
                questionData.push(question.answer);
            });

            const counts = {};
            questionData.forEach((x) => {
                if (x in counts) {
                    counts[x] = counts[x] + 1;

                    return;
                }
                counts[x] = (counts[x] || 0) + 1;
            });

            const dataValues = [];
            for (const prop in counts) {
                dataValues.push(counts[prop]);
            }

            return dataValues;
        },
        imageTotal(questionGroup) {
            let total = 0;

            questionGroup.forEach((question) => {
                if (question.type === 'Image' && question.answer) {
                    total++;
                }
            });

            return [total];
        },
        fileTotal(questionGroup) {
            let total = 0;

            questionGroup.forEach((question) => {
                if (question.type === 'File' && question.answer) {
                    total++;
                }
            });

            return [total];
        },
        signatureTotal(questionGroup) {
            let total = 0;

            questionGroup.forEach((question) => {
                if (question.type === 'Signature' && question.answer) {
                    total++;
                }
            });

            return [total];
        },
        retrieveUser(userId) {
            const foundUser = this.users.find((user) => user.id === userId);

            if (foundUser === undefined) {
                return false;
            }

            return foundUser;
        },
        questionIcon(questionType) {
            switch (questionType) {
                case 'Text':
                    return 'file-text';
                case 'Dropdown':
                    return 'list';
                case 'Single Choice':
                    return 'grid';
                case 'Checkbox':
                    return 'check-square';
                case 'Image':
                    return 'image';
                case 'File':
                    return 'file';
                case 'Date':
                    return 'calendar';
                case 'Formula':
                    return 'bar-chart-2';
                case 'Signature':
                    return 'pen-tool';
                default:
                    return 'Text';
            }
        },
        setDateRange() {
            this.dateMenu = false;
        },
        clearDateRange() {
            this.dateRange = [];
        },
        navigateBack() {
            this.$router.go(-1);
        },
        getSubmissionQuery() {
            if (!this.authUser) return;

            let query = {};
            if (this.authUser.role === 'Admin' || this.authUser.role === 'GlobalManager') {
                query = {
                    form_id: this.submissionId,
                    status: 'Submitted'
                };
            } else {
                query = {
                    manager_id: this.authUser.id,
                    form_id: this.submissionId,
                    status: 'Submitted'
                };
            }

            let lessThanDate = '';
            let greaterThanDate = '';

            if (this.dateRange.length === 1) {
                lessThanDate = this.$moment(this.dateRange[0]).add(1, 'days');
                greaterThanDate = this.$moment(this.dateRange[0]);

                query.createdAt = {
                    $gte: greaterThanDate,
                    $lte: lessThanDate
                };
            } else if (this.dateRange.length === 2) {
                // eslint-disable-next-line vue/no-mutating-props, vue/no-side-effects-in-computed-properties
                const sortedDateRange = this.dateRange.sort((a, b) => new Date(a) - new Date(b)).map((d) => this.$moment(d).format('YYYY-MM-DD'));

                lessThanDate = this.$moment(sortedDateRange[1]).add(1, 'days');
                greaterThanDate = this.$moment(sortedDateRange[0]);

                query.createdAt = {
                    $gte: greaterThanDate,
                    $lte: lessThanDate
                };
            }

            return query;
        },
        async generateCSV() {
            let requestData = {
                query: this.getSubmissionQuery(),
                displayText: this.displayText,
                displayDropdown: this.displayDropdown,
                displayMultipleChoice: this.displayMultipleChoice,
                displayCheckbox: this.displayCheckbox,
                displayImage: this.displayImage,
                displayFile: this.displayFile,
                displayDate: this.displayDate,
                displayFormula: this.displayFormula,
                displaySignature: this.displaySignature
            };
            try {
                let result = await this.$store.dispatch('csvs/create', requestData);
                if (result?.url) {
                    window.open(`${this.$feathersConnectionString}${result.url}`, '_blank').focus();
                }
            } catch (error) {
                this.showError(error);
            }
        },
        ...mapActions('app', ['showSuccess', 'showError'])
    },
    computed: {
        submissionId() {
            return this.$route.params && this.$route.params.id;
        },
        submissionName() {
            if (this.submissions.length) {
                return this.submissions[0].name;
            }

            return '';
        },
        submissionTitle() {
            if (this.submissions.length) {
                return this.submissions[0].sections[this.currentPage - 1].title;
            }

            return '';
        },
        submissionDescription() {
            if (this.submissions.length) {
                return this.submissions[0].sections[this.currentPage - 1].description;
            }

            return '';
        },
        submissionQuestions() {
            if (this.submissions.length) {
                return this.submissions[0].sections[this.currentPage - 1].questions;
            }

            return '';
        },
        submissionsParams() {
            const query = this.getSubmissionQuery();

            return {
                query: query
            };
        },
        usersParams() {
            return {
                query: {}
            };
        },
        sectionsLength() {
            if (this.submissions.length) {
                return this.submissions[0].sections.length;
            } else {
                return 1;
            }
        },
        prevDisabled() {
            if (this.currentPage === 1) {
                return true;
            }

            return false;
        },
        nextDisabled() {
            if (this.currentPage >= this.sectionsLength) {
                return true;
            }

            return false;
        },
        ...mapGetters('apiAuth', { authUser: 'user' })
    }
};
</script>
<style lang="scss" scoped>
.form-editor-page-wrapper {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;

    .form-editor-container {
        height: 100%;

        .form-editor-sidebar {
            flex-grow: 0;
            min-width: 90px;
            max-width: 90px;
        }
        .form-editor-page {
            flex-grow: 1;
        }
    }
}

.title-section {
    position: relative;

    .download-btn {
        position: absolute;
        right: 0;
    }
}

.text-cap {
    // white-space: nowrap;
    overflow: hidden;
    // text-overflow: clip;
}
</style>
