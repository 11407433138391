<template>
    <div>
		<v-card-title class="text-h1 font-weight-600 pa-0 mb-4">My Forms</v-card-title>
		<v-card-subtitle class="text-subtitle-1 pa-0 mb-4">Access all the forms that have been assigned to you. </v-card-subtitle>
		<v-card class="card-shadow pa-4">
			<div class="d-md-flex justify-space-between">
				<v-tabs
					v-model="tab"
					active-class="active"
          tabs-item-max-width="20"
          tabs-item-min-width="20"
          @change="changeTab()"
          :show-arrows="false"
				>
					<v-tabs-slider color="#6F70FF"></v-tabs-slider>

					<v-tab href="#assigned-forms" class="tab">
						<feather v-if="!isMobile" type="file-plus" size="20" class="mr-1" stroke-width="1.5"></feather>
						<span class="font-weight-600">Assigned</span>
					</v-tab>

					<v-tab href="#in-progress-forms" class="tab">
						<feather v-if="!isMobile" type="edit" size="20" class="mr-1" stroke-width="1.5"></feather>
						<span class="font-weight-600">In Progress</span>
					</v-tab>

					<v-tab href="#completed-forms" class="tab">
						<feather v-if="!isMobile" type="list" size="20" class="mr-1" stroke-width="1.5"></feather>
						<span class="font-weight-600">Completed</span>
					</v-tab>
				</v-tabs>

				<div class="flex-row d-flex xs-margin-top">
					<v-text-field
						v-model="searchQuery"
						hide-details
						outlined
						dense
						class="input-style font-size-input text-color-gray mt-0 mb-4 search-input"
						placeholder="Search"
					>
						<template slot="prepend-inner">
							<feather
								type="search"
								size=".875rem"
								stroke-width="1.5"
								color="#adb5bd"
								class="eye mt-1 mr-1"
							>
							</feather>
						</template>
					</v-text-field>

					<v-menu transition="slide-y-transition" offset-y :position-x="50">
						<template #activator="menu">
                <v-tooltip bottom>
                    <template #activator="tooltip">
                        <v-btn class="ml-4" icon v-on="{ ...menu.on, ...tooltip.on }">
                            <v-avatar size="33" :color="filterOption ? '#B7B8FF' : '#F3F4FD'">
                                <feather type="filter" class="text-darker" size="1.2rem" stroke-width="1.5"></feather>
                            </v-avatar>
                        </v-btn>
                    </template>

                    <span>Filter by</span>
                </v-tooltip>
						</template>

						<v-list min-width="192" max-width="192" nav dense>		
                <v-subheader>Filters</v-subheader>
                <v-list-item-group v-model="selectedFilter">
                    <v-list-item @click="selectFilter(item.filter)" link v-for="(item, i) in filters" :key="i">
                        <v-list-item-content class="pa-0">
                            <v-list-item-title class="text-darker">{{ item.name }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
						</v-list>
					</v-menu>

          <v-menu
              ref="menu"
              v-model="dateMenu"
              :close-on-content-click="false"
              offset-y
              bottom
              left
              min-width="auto"
          >
              <template #activator="menu">
                  <v-tooltip bottom>
                      <template #activator="tooltip">
                          <v-btn class="ml-4" icon v-on="{ ...menu.on, ...tooltip.on }">
                              <v-avatar size="33" :color="dateRange.length ? '#B7B8FF' : '#F3F4FD'">
                                  <feather type="calendar" class="text-darker" size="1.2rem" stroke-width="1.5"></feather>
                              </v-avatar>
                          </v-btn>
                      </template>

                      <span>Filter between two dates</span>
                  </v-tooltip>
                  
              </template>
              <v-date-picker
                      v-model="dateRange"
                      no-title
                      scrollable
                      range
                      color="#6F70FF"
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="clearDateRange()"
                  >
                      Clear
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="setDateRange()"
                  >
                      OK
                  </v-btn>
              </v-date-picker>
          </v-menu>
				</div>
			</div>
			<v-tabs-items v-model="tab">
				<v-tab-item value="assigned-forms">
					<assigned-forms :searchQuery="searchQuery" :filterOption="filterOption" :dateRange="dateRangeValues"></assigned-forms>
				</v-tab-item>
				<v-tab-item value="in-progress-forms">
					<in-progress-forms :searchQuery="searchQuery" :filterOption="filterOption" :dateRange="dateRangeValues"></in-progress-forms>
				</v-tab-item>
				<v-tab-item value="completed-forms">
					<completed-forms :searchQuery="searchQuery" :filterOption="filterOption" :dateRange="dateRangeValues"></completed-forms>
				</v-tab-item>
			</v-tabs-items>
		</v-card>
		<!-- <pre>{{ forms }}</pre> -->
    </div>
</template>

<script>
import AssignedForms from "./AssignedForms.vue";
import InProgressForms from "./InProgressForms.vue";
import CompletedForms from "./CompletedForms.vue"
import isMobile from "../../../plugins/isMobile";

export default {
	components: {
        AssignedForms,
		InProgressForms,
		CompletedForms
    },
    data() {
        return {
            isMobile: isMobile(),
			searchQuery: '',
			filterOption: '',
            selectedFilter: 'ascending',
            dateMenu: '',
            dateRange: [],
            dates: [],
			filters: [
                {
                    name: "Form Name (a-z)",
					filter: 'ascending'
                },
				{
                    name: "Form Name (z-a)",
					filter: 'descending'
                },
                {
                    name: "Date added (newest)",
					filter: 'latestCreated'
                },
                {
                    name: "Date added (oldest)",
					filter: 'oldestCreated'
                },
                {
                    name: "Date updated (newest)",
					filter: 'latestUpdated'
                },
                {
                    name: "Date updated (oldest)",
					filter: 'oldestUpdated'
                }
            ]
        };
    },
	methods: {
		selectFilter(filterOption) {
			this.filterOption = filterOption
		},
        setDateRange() {
            this.dateMenu = false
        },
        clearDateRange() {
            // this.dateMenu = false
            this.dateRange = []
        },
        changeTab() {
            this.dateRange = []
        }
	},
    computed: {
        tab: {
            set (tab) {
                this.$router.replace({ query: { ...this.$route.query, tab } })
            },
            get () {
                return this.$route.query.tab
            }
        },
        dateRangeValues() {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            return this.dateRange.sort((a, b) => new Date(a) - new Date(b)).map(d => this.$moment(d).format('YYYY-MM-DD'))
        }
    }
};
</script>
<style lang="scss" scoped>
.active {
	color: #6F70FF !important;

	i {
		color: #6F70FF !important;
	}
}

@media screen and (max-width: 640px) {
    .xs-margin-top {
        margin-top: 1rem;
    }

    .tab {
        padding: 0 5px 0 0;
    }

    .search-input {
      max-width: 12rem;
    }
}

@media screen and (max-width: 960px) {
    .xs-margin-top {
        margin-top: 1rem;
    }

    .tab {
        padding: 0 20px 0 0;
    }

    .search-input {
      width: 15rem;
    }
}

@media screen and (min-width: 961px) {
    .search-input {
      width: 15rem;
    }
}
</style>

<style lang="scss">
.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
    display: none!important;
    visibility: none!important;
}
</style>
