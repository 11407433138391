<template>
    <div v-if="!filteredSubmissions.length && !searchQuery.length" class="text-center pa-3 mb-2">
        <h1 class="text-h1 font-weight-600 text-black text-center mb-2 pt-5">No analytics available</h1>
        <p class="text-black font-size-root text-center font-weight-thin mb-2">You will receive analytics once a form has been submitted.</p>
        <v-img class="my-10" :src="coverIllustration" max-height="300" contain />
    </div>
    <div v-else-if="!filteredSubmissions.length && searchQuery.length" class="text-center pa-3 mb-2">
        <h1 class="text-h1 font-weight-600 text-black text-center mb-2 pt-5">Not Found</h1>
        <p class="text-black font-size-root text-center font-weight-thin mb-2">No results found for: {{ searchQuery }}</p>
        <v-img class="my-10" :src="notFoundIllustration" max-height="300" contain />
    </div>
    <v-row v-else-if="filteredSubmissions.length">
        <v-col v-for="(group, index) in formGroups.slice((currentPage - 1) * perPage, currentPage * perPage)" :key="index" sm="6" md="6" lg="4" xl="3">
            <v-card class="card-shadow-2 pa-1 ma-2" solo>
                <v-card class="ma-2 mb-0 py-2" color="#F3F4FD">
                    <v-hover>
                        <template v-slot:default="{ hover }">
                            <v-card class="ma-2 mb-0 py-2" color="#F3F4FD">
                                <v-img :src="formIllustration" max-height="200" max-width="326" contain />
                                <v-fade-transition>
                                    <v-overlay v-if="hover" absolute opacity="0.1">
                                        <router-link :to="`/analytics/${group[1][0].form_id}`" class="text-decoration-none btn-hover no-default-hover">
                                            <v-btn elevation="0" height="40" class="font-weight-600 text-capitalize btn-primary rounded-xs mr-4" color="#6F70FF">
                                                <span class="text-white">View Analytics</span>
                                            </v-btn>
                                        </router-link>
                                    </v-overlay>
                                </v-fade-transition>
                            </v-card>
                        </template>
                    </v-hover>
                </v-card>
                <div class="d-flex justify-space-between align-center">
                    <div>
                        <v-card-title class="font-weight-600 subtitle-2 d-block pl-2">{{ group[0] }}</v-card-title>
                        <v-card-subtitle
                            class="font-weight-400 text-light d-flex align-center pt-2 pl-2"
                            v-if="(authUser.role === 'GlobalManager' || authUser.role === 'Admin') && group[1][0].manager_id !== authUser.id">
                            <feather type="user" class="text-darker mr-1" size="1.2rem" stroke-width="1.2"></feather>
                            <span>
                                Form created by&nbsp;<span class="font-weight-600">{{ retrieveUserWhoCreatedForm(group[1][0].manager_id) }}</span>
                            </span>
                        </v-card-subtitle>
                        <v-card-subtitle
                            class="font-weight-400 text-light d-flex align-center pt-2 pl-2"
                            v-if="(authUser.role === 'GlobalManager' || authUser.role === 'Admin') && group[1][0].manager_id === authUser.id">
                            <feather type="user" class="text-darker mr-1" size="1.2rem" stroke-width="1.2"></feather>
                            <span> Form created by you </span>
                        </v-card-subtitle>
                    </div>
                    <div class="font-weight-400 label text-light d-flex align-center mr-2">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <feather type="file-text" class="text-primary mr-1" size="1.2rem" stroke-width="1.2" v-bind="attrs" v-on="on"></feather>
                                {{ group[1].length }}
                            </template>
                            <span>Total Submissions</span>
                        </v-tooltip>
                    </div>
                </div>
            </v-card>
        </v-col>
        <v-col v-if="totalPagesLength > 1" cols="12" class="mt-4 d-flex justify-end">
            <v-pagination prev-icon="fa fa-angle-left" next-icon="fa fa-angle-right" class="pagination" color="#6F70FF" v-model="currentPage" :length="totalPagesLength" circle></v-pagination>
        </v-col>
        <!-- <pre>{{ formGroups }}</pre> -->
    </v-row>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import { mapGetters } from 'vuex';

export default {
    props: {
        searchQuery: {
            type: String,
            required: true
        },
        filterOption: {
            type: String,
            required: true
        },
        dateRange: {
            type: Array,
            required: true
        }
    },
    mixins: [makeFindMixin({ service: 'submissions', watch: true }), makeFindMixin({ service: 'users', watch: true })],
    data() {
        return {
            formIllustration: require('@/assets/img/illustrations/undraw_pie_graph_x-9-dy.svg'),
            coverIllustration: require('@/assets/img/illustrations/undraw_pie_graph_re_fvol.svg'),
            notFoundIllustration: require('@/assets/img/illustrations/undraw_no_data_re_kwbl.svg'),
            currentPage: 1,
            perPage: 16,
            totalPages: 1
        };
    },
    methods: {
        retrieveUserWhoCreatedForm(id) {
            let user = this.retrieveUser(id);
            if (!user) return false;
            return `${user.first_name} ${user.last_name}`;
        },
        retrieveUser(id) {
            const foundUser = this.users.find((user) => user.id === id);

            if (foundUser === undefined) {
                return false;
            }

            return foundUser;
        }
    },
    computed: {
        usersParams() {
            return {
                query: {
                    $sort: true
                }
            };
        },
        filteredSubmissions() {
            return this.submissions.filter((submission) => {
                const submissionObj = JSON.stringify(submission);
                return submissionObj.includes(this.searchQuery);
            });
        },
        submissionsParams() {
            if (!this.authUser) return;

            let query = {};
            if (this.authUser.role === 'Admin' || this.authUser.role === 'GlobalManager') {
                query = {
                    status: 'Submitted',
                    $sort: {
                        updatedAt: -1
                    }
                };
            } else {
                query = {
                    manager_id: this.authUser.id,
                    status: 'Submitted',
                    $sort: {
                        updatedAt: -1
                    }
                };
            }

            let lessThanDate = '';
            let greaterThanDate = '';

            if (this.dateRange.length === 1) {
                lessThanDate = this.$moment(this.dateRange[0]).add(1, 'days');
                greaterThanDate = this.$moment(this.dateRange[0]);

                query.createdAt = {
                    $gte: greaterThanDate,
                    $lte: lessThanDate
                };
            } else if (this.dateRange.length === 2) {
                lessThanDate = this.$moment(this.dateRange[1]).add(1, 'days');
                greaterThanDate = this.$moment(this.dateRange[0]);

                query.createdAt = {
                    $gte: greaterThanDate,
                    $lte: lessThanDate
                };
            }

            if (this.filterOption === 'ascending') {
                query.$sort = {
                    name: 1
                };
            } else if (this.filterOption === 'descending') {
                query.$sort = {
                    name: -1
                };
            } else if (this.filterOption === 'latestCreated') {
                query.$sort = {
                    createdAt: -1
                };
            } else if (this.filterOption === 'oldestCreated') {
                query.$sort = {
                    createdAt: 1
                };
            } else if (this.filterOption === 'latestUpdated') {
                query.$sort = {
                    updatedAt: 1
                };
            } else if (this.filterOption === 'oldestUpdated') {
                query.$sort = {
                    updatedAt: 1
                };
            }

            return {
                query: query
            };
        },
        totalPagesLength() {
            this.totalPages = Math.ceil(this.formGroups.length / this.perPage);

            return this.totalPages;
        },
        formGroups() {
            if (this.filteredSubmissions.length) {
                const groups = this.filteredSubmissions.reduce((acc, value) => {
                    if (!acc[value.name]) {
                        acc[value.name] = [];
                    }

                    acc[value.name].push(value);

                    return acc;
                }, {});

                const formGroups = Object.entries(groups).map((item) => {
                    return item;
                });

                return formGroups;
            }

            return [];
        },
        ...mapGetters('apiAuth', { authUser: 'user' })
    }
};
</script>
<style lang="scss" scoped>
.card-shadow-2 {
    box-shadow: 0 0 1rem 0 rgb(136 152 170 / 15%) !important;
}

.label {
    font-size: 0.8rem;
}
</style>



